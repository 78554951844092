import isPropValid from '@emotion/is-prop-valid';
import { curry, path } from 'lodash/fp';
import { scroller } from 'react-scroll';
import { compare } from '~lib/dates';
import { COUPLE, FAMILY } from '~lib/constants/quoteStatuses';
import min from 'lodash/min';
import moment from 'moment';

export function isRunningOnServerSide() {
  return typeof window === 'undefined';
}

export function isRunningOnClientSide() {
  return typeof window !== 'undefined';
}

export const isMobile = isRunningOnClientSide() && window.innerWidth < 992;
export const isProduction = process.env.NODE_ENV === 'production';
export const isDevelopment = !isProduction;
export const execOnClientOnly = fn => {
  if (isRunningOnClientSide()) {
    fn();
  }
};

export const createClientOnlyExecutable =
  fn =>
  (...args) => {
    return isRunningOnClientSide() ? fn(...args) : null;
  };

export const ssrSafe = object =>
  Object.entries(object).reduce(
    (acc, [key, fn]) => ({
      ...acc,
      [key]: createClientOnlyExecutable(fn),
    }),
    {}
  );

export const onlyOnce = fn => {
  let executed = false;

  return (...args) => {
    if (!executed) {
      executed = true;
      return fn(...args);
    }

    return null;
  };
};

export const noop = () => {};

export const paddLeft = (char, maxLength) => string => {
  const padLength = maxLength - string.length;
  return padLength > 0
    ? Array.from({ length: padLength }, () => char).join('') + string
    : string;
};

export const shouldForwardProp = name => {
  return isPropValid(name) && name !== 'loading';
};

export const capitalize = word =>
  word ? word[0].toUpperCase() + word.slice(1).toLowerCase() : '';

export const base64ToBlob = (str, type = 'octet/stream') => {
  const binaryString = atob(str);
  const arr = new Uint8Array(new ArrayBuffer(binaryString.length));

  for (let i = 0; i < binaryString.length; i++) {
    arr[i] = binaryString.charCodeAt(i);
  }

  return new Blob([arr], { type });
};

export const dropEmpties = object => {
  return Object.entries(object || {})
    .filter(([key, value]) => value != null)
    .reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: value,
      }),
      {}
    );
};

export const range = (from, to) =>
  Array.from({
    length: to - from + 1,
  }).map((_, index) => from + index);

export const swap = (swapElement, idName, list) =>
  list.map(item => (item[idName] === swapElement[idName] ? swapElement : item));

export const chainedPath = basePath => data => {
  const baseData = path(basePath, data);
  return additionalPath => {
    return additionalPath ? path(additionalPath, baseData) : baseData;
  };
};

// use this function instead of one from lodash/fp as the stupid thing will return null (not empty array) for expressions like this pathOr([], 'oms.membership.persons', data);
export const pathOr = curry((or, pathToResult, data) => {
  const result = path(pathToResult)(data);
  return result || or;
});

export const parseQueryParams = queryString => {
  return queryString
    .split('&')
    .map(param => param.split('='))
    .reduce((acc, [key, value]) => {
      acc[key] = decodeURIComponent(value);
      return acc;
    }, {});
};

export const parseStateCodeFromAddress = (addressString = '') => {
  const addressParts = addressString.split(' ');
  return addressParts[addressParts.length - 1];
};

const uint8ArrayToText = (array = []) => {
  let text = '';
  array.forEach(byte => {
    text += String.fromCharCode(byte);
  });
  return text;
};

export const readTextStream = async readableStream => {
  const reader = readableStream.getReader();
  let text = '';
  let done;
  do {
    const chunkInfo = await reader.read();
    done = chunkInfo.done;
    text += uint8ArrayToText(chunkInfo.value);
  } while (!done);

  return text;
};

export const formSafeValues = values => {
  return Object.entries(values).reduce((acc, [name, value]) => {
    return {
      ...acc,
      [name]: value === null ? '' : value,
    };
  }, {});
};

export const waterfall = async (listOfFunctions, { agregateResult } = {}) => {
  const asyncIterable = {
    [Symbol.asyncIterator]() {
      return {
        i: 0,
        value: undefined,
        async next() {
          if (this.i < listOfFunctions.length) {
            this.value = await listOfFunctions[this.i++](this.value);
            return {
              value: this.value,
              done: false,
            };
          }

          return { value: this.value, done: true };
        },
      };
    },
  };

  let results = [];

  for await (const result of asyncIterable) {
    if (agregateResult) {
      results = result;
    } else {
      results.push(result);
    }
  }

  return results;
};

export const scrollTo = (name, { timeout = 200, ...options } = {}) => {
  setTimeout(
    () =>
      scroller.scrollTo(name, {
        duration: 1000,
        delay: 0,
        isDynamic: true,
        offset: 0,
        smooth: 'easeInOutQuint',
        ...options,
      }),
    timeout
  );
};

export const mapWithLast = (array = [], cb) =>
  array.map((item, index) => cb(item, index, index === array.length - 1));

export const getRawText = (content, rawText = '') => {
  return (
    rawText +
    ' ' +
    content
      .map(block => {
        let text = '';
        if (block.text) {
          text += block.text;
        }
        if (block.children) {
          return getRawText(block.children, text);
        }
        return text;
      })
      .join(' ')
  );
};

export const hexToRgb = hex => {
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};

export const isDarkColor = hexColor => {
  const { r, g, b } = hexToRgb(hexColor);
  const colors = [r / 255, g / 255, b / 255].map(v => {
    if (v <= 0.03928) {
      return v / 12.92;
    }

    return Math.pow((v + 0.055) / 1.055, 2.4);
  });

  const luminance =
    0.2126 * colors[0] + 0.7152 * colors[1] + 0.0722 * colors[2];

  return luminance <= 0.179;
};

export const isDarkSanityImage = image => {
  const color = path('asset.metadata.palette.dominant.foreground')(image);

  if (!color) {
    console.warn('isDarkSanityImage - No color palette data found');
  }

  return color && isDarkColor(color);
};

export const traverseObject = (obj, cb) => {
  if (typeof obj !== 'object' || obj == null) {
    return;
  }

  Object.entries(obj).forEach(([propName, propValue]) => {
    cb(propName, propValue, obj);
    if (Array.isArray(propValue)) {
      propValue.forEach((item, index) => {
        cb(index, item, obj);
        traverseObject(item, cb, index);
      });
      return;
    }

    traverseObject(propValue, cb, propName);
  });
};

export const required = argName => {
  throw new Error(`${argName} is required`);
};

export const isEmptyObject = obj => {
  return !Object.keys(obj || {}).length;
};

export const memoizeBy =
  (getCacheKey = required('getCacheKey')) =>
  fn => {
    const cache = new Map();
    return (...args) => {
      const key = getCacheKey(...args);
      if (cache.has(key)) {
        return cache.get(key);
      }

      const result = fn(...args);
      cache.set(key, result);
      return result;
    };
  };

export const dropProp = (name, obj) => {
  if (!obj) {
    return obj;
  }
  const newObj = {
    ...obj,
  };
  delete newObj[name];
  return newObj;
};

export const dropProps = (props = [], obj) => {
  return props.reduce((acc, propToDrop) => {
    return dropProp(propToDrop, acc);
  }, obj);
};

export const createSmartDebounce = () => {
  let previousArgs;
  let timer;
  return (fn, timeout, { dontDebounceIf = () => false } = {}) => {
    return (...args) => {
      if (
        timer &&
        !dontDebounceIf({
          currentArgs: args,
          previousArgs,
        })
      ) {
        clearTimeout(timer);
      }

      previousArgs = args;

      timer = setTimeout(() => {
        fn(...args);
      }, timeout);
    };
  };
};

export const isEfcEligible = status => {
  return ['FAMILY', 'SINGLE_PARENT'].includes(status);
};

export const hasPartner = status => [FAMILY, COUPLE].includes(status);

export const selectDobForSubmit = form => {
  if (
    !form.partnerDob ||
    ['SINGLE', 'SINGLE_PARENT'].includes(form.status.value)
  ) {
    return form.yourDob;
  }

  return compare(form.yourDob.value, form.partnerDob.value) < 0
    ? form.yourDob
    : form.partnerDob;
};

export const postFormDataForm = (url, payload, options) => {
  const form = new FormData();
  Object.entries(payload).forEach(([key, value]) => {
    form.append(key, value);
  });

  return fetch(url, {
    method: 'POST',
    mode: 'cors',
    body: form,
    ...options,
  })
    .then(response => {
      if (path('status')(response) !== 200) {
        throw response;
      }
      return response;
    })
    .then(response => {
      if (response.json) {
        return response.json();
      }
      return response;
    });
};

export const sleep = sleepInMs =>
  new Promise(resolve => {
    setTimeout(resolve, sleepInMs);
  });
export const trackCustomEvent = ({
  category,
  action,
  label,
  value,
  nonInteraction = false,
  transport,
}) => {
  if (typeof window !== 'undefined' && window.ga && window.ga?.getAll) {
    const trackingEventOptions = {
      eventCategory: category,
      eventAction: action,
      eventLabel: label,
      eventValue: value,
      nonInteraction: nonInteraction,
      transport,
    };

    const tracker = window?.ga?.getAll()?.[0];

    //eslint-disable-next-line no-unused-expressions
    tracker?.send('event', trackingEventOptions);
  }
};

export const getSessionItem = key => sessionStorage.getItem(key);

export const setSessionItem = (key, value) =>
  sessionStorage.setItem(key, value);

export const removeSessionItem = key => sessionStorage.removeItem(key);

export const windowHasSelection = () => {
  if (typeof window === 'undefined') {
    return false;
  }
  return window?.getSelection()?.type === 'Range';
};

/**
 * Convert breakpoint object to array so theme-ui can use it.
 * Add custom keys to array items for easier referencing
 *
 * @see {@link https://theme-ui.com/theming/#breakpoints}
 *
 * @param {Object} bps
 * @param {string} bps.key - breakpoints screen size type e.g. 'xs'
 * @param {string} bps.value - breakpoint screen size value e.g. '768px'
 *
 * @returns {Array}
 */
export const createBreakpointArray = bps => {
  return Object.entries(bps).reduce((arr, [key, value]) => {
    arr.push(value);
    arr[key] = value;
    return arr;
  }, []);
};

/**
 * Create min and max width query strings based on the breakpoints
 * object.
 *
 * @param {Object} bps
 * @param {string} bps.key - breakpoints screen size type e.g. 'xs'
 * @param {string} bps.value - breakpoint screen size value e.g. '768px'
 *
 * @returns {Object}
 */
export const createMediaQueryObject = bps => {
  return Object.entries(bps).reduce((obj, [key, value]) => {
    obj[key] = `@media (min-width: ${value})`;
    obj[`${key}Inverted`] = `@media (max-width: ${value})`;
    return obj;
  }, {});
};

export const fetchSvgInline = image =>
  fetch(image?.src?.asset?.url)
    .then(response => response.text())
    .then(response => {
      const svgStr = response;

      if (svgStr.indexOf('<svg') === -1) {
        return;
      }

      const span = document.createElement('span');

      span.innerHTML = svgStr;

      const inlineSvg = span.getElementsByTagName('svg')[0];

      inlineSvg.setAttribute('aria-label', image.alt || '');
      inlineSvg.setAttribute('class', image.className); // IE doesn't support classList on SVGs
      inlineSvg.setAttribute('focusable', false);
      inlineSvg.setAttribute('role', 'img');

      if (image.height) {
        inlineSvg.setAttribute('height', image.height);
      }

      if (image.width) {
        inlineSvg.setAttribute('width', image.width);
      } else {
        inlineSvg.removeAttribute('width');
      }

      return inlineSvg;
    })
    .catch(error => console.error(error));

export const minDate = (...dates) =>
  min(
    (dates || [])
      .flat()
      .filter(date => date && moment(date).isValid())
      .map(date => moment(date))
  ) || undefined;

export * from './addressUtil';
export * from './text';
export * from './brand';
