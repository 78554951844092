import React, { useEffect } from 'react';
import styled from '@emotion/styled';
import { Box } from 'rebass';
import { inputStyles } from '~common/atoms/Input';
import { selectStyles } from '~common/atoms/DropDown';
import Loading from '~common/atoms/Loading';
import { backgroundColorStyle } from '~common/molecules/form-controls/Control';
import useMarketoForm from './useMarketoForm';

const StyledMarketoForm = styled(Box)`
  &&&&&&&&&& form {
    width: 100% !important;
    .mktoFieldDescriptor.mktoFormCol {
      margin-bottom: 0 !important;
      width: 100%;
    }

    .mktoButtonRow {
      width: 100%;
    }

    .mktoInvalid {
      border-color: ${props => props.theme.colors.error}!important;
    }

    .mktoButtonWrap {
      width: 100%;
      margin-left: 0 !important;
      display: flex;
      justify-content: center;
      button {
        width: 153px;
        margin: 0px;
        min-width: 0px;
        appearance: none;
        text-decoration: none;
        font-weight: 600;
        border: 2px solid transparent;
        border-radius: 60px;
        padding: 8px 22px;
        font-size: 16px;
        line-height: 1.2;
        text-align: center;
        display: block;
        cursor: pointer;
        white-space: nowrap;
        transition: 0.2s background-color ease-in-out, 0.2s border-color ease-in-out, 0.2s color ease-in-out;
        border-color: ${props => props.theme.colors.primary};
        background-color: ${props => props.theme.colors.primary};
        color: ${props => props.theme.colors.white};
        &:hover {
          background-color: ${props => props.theme.colors.blue400};
          border-color: ${props => props.theme.colors.blue400};
        }
        &:active {
          border-color: ${props => props.theme.colors.blue600};
          background-color: ${props => props.theme.colors.blue600};
          color: ${props => props.theme.colors.white};
        },
      }
    }

    textarea {
      border: 1px solid ${props => props.theme.colors.borders};
      height: 160px;
      width: 100%;
      font-size: ${props => props.theme.fontSizes[2]}px;
      transition: all 200ms ease-in-out;
      padding: 6px 13px;
      appearance: none;
      outline: none;
      resize: none;
      &:focus {
        outline: none;
        box-shadow: 0 0 5px ${props => props.theme.colors.primary};
      }
    }

    .mktoFieldWrap {
      display: grid;
      flex-direction: column;
      margin-bottom: 18px;
      ${props => props.theme.mq.tabletL} {
        margin-bottom: 30px;
      }
      width: 100%;
    }

    .mktoAsterix,
    .mktoGutter,
    .mktoOffset {
      display: none;
    }

    .mktoLabel {
      font-weight: normal;
      font-size: 13px;
      color: ${props => props.theme.colors.text.light};
      margin-bottom: 8px;
      width: 100% !important;
    }

    .mktoRadioList label {
      margin-bottom: 0;
      margin-top: 0;
    }

    .mktoField,
    .mktoLogicalField {
      width: 100% !important;
    }

    .mktoCheckboxList,
    .mktoRadioList {
      width: 100% !important;
      display: flex;
      flex-wrap: wrap;
      gap: 5px;
      padding: 0;

      ${props => props.theme.mq.tabletPInverted} {
        gap: 0;
        row-gap: 5px;
        flex-direction: column;
      }

      input {
        display: none;
      }

      input:checked + label {
        color: ${props => props.theme.colors.primary};
        border-color: ${props => props.theme.colors.primary};
      }

      label[for='Department'] {
        display: none;
      }

      label {
        font-weight: 600;
        transition: all 300ms ease-in-out;
        margin: 0 !important;
        min-width: 90px;
        max-width: 91px;
        text-align: center;
        cursor: pointer;
        height: auto;
        appearance: none;
        -webkit-appearance: none;
        outline: none;
        color: ${props => props.theme.colors.dark};
        border: 1px solid ${props => props.theme.colors.borders};
        text-transform: none;
        line-height: 14px;
        font-size: ${props => props.theme.fontSizes[1]}px;
        padding: 13px 0;
        ${backgroundColorStyle};
        &:focus {
          outline: none;
        }
      }
    }

    ${selectStyles}
    select {
      border: 1px solid ${props => props.theme.colors.borders};
      padding: 7px 13px;
      line-height: 24px;
    }

    .mktoTextField,
    .mktoEmailField,
    .mktoTelField {
      ${inputStyles}
      &:focus {
        outline: none;
        box-shadow: 0 0 5px ${props => props.theme.colors.primary};
      }
    }
  }
`;

const MarketoForm = ({
  formId,
  values,
  onSubmit,
  onForm,
  loading: externalLoading,
  ...props
}) => {
  const {
    id,
    loading: formLoading,
    form,
  } = useMarketoForm({
    baseUrl: process.env.MARKETO_FORM_BASE_URL,
    munchkinId: process.env.MARKETO_FORM_MUNCHKIN_ID,
    formId,
    onLoad: onForm,
    onSuccess: values => {
      if (onSubmit) {
        onSubmit(values);
      }
      return false;
    },
  });

  const loading = formLoading || externalLoading;

  useEffect(() => {
    if (values && form) {
      form.setValues(values);
    }
  }, [values, form]);

  return (
    <StyledMarketoForm {...props}>
      {loading && <Loading />}
      <form id={id} />
    </StyledMarketoForm>
  );
};

export default MarketoForm;
