import React from 'react';
import styled from '@emotion/styled';
import IconHeading from '../atoms/IconHeading';
import { Box, Flex } from 'rebass';
import { useThemeUI } from 'theme-ui';
import { StandardHeading, StandardText } from '../atoms/typography';

const AlertContainer = styled(Box)`
  color: ${props => (!props.success ? '#ff0000' : '#447E00')};
  background-color: ${props => (!props.success ? '#FFDDDD' : '#EBFED6')};
  padding: 15px 20px;
`;

// This is the compoennt for SSO validation alert message display
// default is error (red), pass success to be green
export default ({ error, success, type, children }) => {
  return (
    <AlertContainer success={success} mb={3}>
      <IconHeading
        icon={error ? 'alert' : 'oms-horn'}
        fill={error ? '#ff0000' : '#447E00'}
        text={error || success}
        mb="0px"
      />
      {children}
    </AlertContainer>
  );
};

const Container = styled(Flex)`
  color: ${props => props.theme.colors.alert[props.type].color};
  background: ${props => props.theme.colors.alert[props.type].background};
  word-break: break-word;
  border: 1px solid ${props => props.theme.colors.alert[props.type].color};
  border-radius: 5px;
`;

export const Alert = ({
  type,
  children,
  px = 3,
  py = 2,
  title = '',
  ...props
}) => {
  const { theme } = useThemeUI();
  const color = theme.colors.alert[type].color;
  return (
    <Container
      flexDirection="column"
      theme={theme}
      justifyContent="center"
      alignItems="center"
      type={type}
      px={px}
      py={py}
      {...props}
    >
      {title && (
        <StandardHeading color={color} mb={3} fontSize={3}>
          {title}
        </StandardHeading>
      )}
      <StandardText color={color}>{children}</StandardText>
    </Container>
  );
};
