import { useLocation } from '@reach/router';
import { parseQueryParams } from '~lib/util';
import { useMemo } from 'react';

export default () => {
  const location = useLocation();
  return useMemo(() => {
    return parseQueryParams(location?.search.slice(1));
  }, [location.search]);
};
