import React from 'react';
import { isRunningOnClientSide } from '../util';

function BrowserRenderOnly(Component) {
  return class extends React.Component {
    render() {
      return isRunningOnClientSide() ? (
        <Component {...this.props}></Component>
      ) : null;
    }
  };
}

export default BrowserRenderOnly;
