import 'core-js/es/typed-array';
import 'core-js/es/object';
import React, { useState, createContext, useCallback, useEffect } from 'react';
import { useApolloClient, useMutation } from '@apollo/client';
import { Auth, Amplify } from 'aws-amplify';
import * as Sentry from '@sentry/browser';
import { useLocation } from '@reach/router';
import axios from 'axios';
import isUndefined from 'lodash/isUndefined';
import { isRunningOnClientSide, removeSessionItem } from '~lib/util';
import { LogActivity, DrawerQuery } from 'queries/oms/common.graphql';
import {
  getConversionErrorMessage,
  getErrorMessageByCode,
} from './sso-messages';
// import useAvantSso from './useAvantSso';
// import useRegisterUser from './useRegisterUser';
import useLocalStorage from '~lib/hooks/useLocalStorage';
import { cloneDeep } from '@apollo/client/utilities';
import useQueryParams from '~lib/hooks/useQueryParams';
import { useLocale } from '~common/locales';
import sentry from '~lib/sentry';

//TODO: refactor this file so it gets split up into multiple files
// it is too big atm.
// It contains independent semantic fragments which can be easily moved out to another file

const REMEMBER_USERNAME_KEY = 'rememberedUsername';

// Configure AWS Amplify
const AMPLIFY_CONFIG = {
  aws_cognito_region: process.env.DHF_AWS_COGNITO_REGION,
  aws_user_pools_id: process.env.DHF_AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id: process.env.DHF_AWS_USER_POOLS_WEB_CLIENT_ID,
};

if (isRunningOnClientSide()) {
  AMPLIFY_CONFIG.storage = window.sessionStorage;
}

Amplify.configure(AMPLIFY_CONFIG);

const initialState = {
  userData: null,
  mfa: null,
  resetPasswordData: null,
  verificationData: null,
  error: null,
  loading: false,
  login: userData => {},
  logout: () => {},
};

// User Conversion - has three types of entry points
// Login Flow - user enters member number - requires basic auth
// Reset Flow - user comes with DOB and member number - NO basic auth, verification required
// Register Flow - user comes with member number - NO basic auth, verification required

const AuthContext = createContext(initialState);

const AuthProvider = ({ children, ...props }) => {
  const [state, setState] = useState({});
  const [logActivity] = useMutation(LogActivity);
  // const register = useRegisterUser();
  const [rememberedUsername, setRememberedUsername] = useLocalStorage(
    REMEMBER_USERNAME_KEY
  );
  const client = useApolloClient();
  const location = useLocation();
  const queryParams = useQueryParams();

  // Checks if the user is logged in
  const isLoggedIn = useCallback(() => {
    return state.userData && state.userData.username;
  }, [state.userData]);

  // Checks if the user has permission to view this page
  const hasPermission = useCallback(() => {
    return !!(
      !location.pathname.includes('/oms') ||
      location.pathname.includes('/oms/sso') ||
      isLoggedIn()
    );
  }, [isLoggedIn, location.pathname]);

  const handleRememberUsername = useCallback(
    formData => {
      if (formData.rememberUsername) {
        setRememberedUsername(formData.username);
      } else {
        setRememberedUsername(undefined);
      }
    },
    [setRememberedUsername]
  );

  const successLoginNavigate = useCallback(async () => {
    let nextPage = queryParams?.next || '/oms';
    nextPage = nextPage.startsWith('/') ? nextPage : `/${nextPage}`;
    window.location.assign(`${process.env.DHF_BASE_URL}${nextPage}`);
  }, [queryParams]);

  useEffect(() => {
    if (
      [
        '/oms/sso/login',
        '/oms/sso/login/',
        '/oms/sso/mfa',
        '/oms/sso/mfa/',
        '/oms/sso/verifyInvitation',
        '/oms/sso/verifyInvitation/'
      ].includes(location.pathname) &&
      state.userData &&
      !queryParams?.link
    ) {
      successLoginNavigate();
    }
  }, [state.userData, queryParams, location.pathname, successLoginNavigate]);

  // Load the initial user
  useEffect(() => {
    getUser()
      .then(userData => {
        setState(currentState => ({ ...currentState, userData }));
      })
      .catch(() =>
        setState(currentState => ({ ...currentState, userData: null }))
      );
  }, []);

  // Redirect to login page when permission is denied
  // state.userData isn't defined until the initial user loads.
  useEffect(() => {
    if (state.hasOwnProperty('userData') && !hasPermission()) {
      window.location.assign(`${process.env.DHF_BASE_URL}/oms/sso/login`);
    }
  }, [hasPermission, state, state.userData]);

  // Get the access token from the current session
  const getToken = async () => {
    try {
      const session = await Auth.currentSession();
      return session.getAccessToken().getJwtToken();
    } catch (error) {
      return null;
    }
  };

  // Get the current authenticated user
  const getUser = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      return user || null;
    } catch (err) {
      return null;
    }
  };

  // Logout and redirect to the login page
  const logout = useCallback(async ({ reason } = {}) => {
    removeSessionItem('hasPopupShown');
    setState(currentState => ({
      ...currentState,
      userData: null,
      mfa: null,
      resetPasswordData: null,
      verificationData: null,
      error: null,
      loading: false,
    }));

    Sentry.withScope(scope => {
      scope.setUser(null);
    });

    await Auth.signOut();
    window.location.assign(
      `${process.env.DHF_BASE_URL}/oms/sso/login`,
      reason
        ? {
            state: {
              alert: {
                type: 'info',
                message: reason,
              },
            },
          }
        : undefined
    );
  }, []);

  // Checks for token and logs out if not found
  const checkForToken = useCallback(async () => {
    const token = await getToken();

    if (!token) {
      await logout();
    }
  }, [logout]);

  const loginBase = useCallback(({ username, password }) => {
    return Auth.signIn({
      username: username.toLowerCase(),
      password,
    });
  }, []);

  const loginFailure = useCallback(
    async error => {
      console.error(error);
      sentry(error);
      setState(currentState => ({
        ...currentState,
        error,
        loading: false,
      }));

      await client.clearStore();
    },
    [client, state]
  );

  const loginSuccess = useCallback(
    async redirect => {
      try {
        await client.clearStore();

        if (redirect) {
          const jwtToken = await getToken();
          const { redirectUrl } = (
            await axios.get('/sso/linkAvant', {
              headers: { authorization: `Bearer ${jwtToken}` },
            })
          ).data;
          window.location.href = redirectUrl;
          return;
        }

        await logActivity({
          variables: { input: { type: 'LOGIN' } },
          update: (store, { data: { logActivity } }) => {
            try {
              const data = cloneDeep(store.readQuery({ query: DrawerQuery }));

              if (data && data.oms) {
                data.oms.activities.unshift(logActivity.activity);
                store.writeQuery({ query: DrawerQuery, data });
              }
            } catch (err) {
              console.error(err);
            }
          },
        });

        const userData = await getUser();

        Sentry.withScope(scope => {
          scope.setUser({
            id: userData.username,
            username: userData.attributes.email,
            email: userData.attributes.email,
          });
        });

        setState(currentState => ({
          ...currentState,
          userData,
          loading: false,
          mfa: null,
        }));
      } catch (error) {
        loginFailure(error);
      }
    },
    [client, logActivity, loginFailure, state]
  );

  // Login and redirect to the OMS
  const login = useCallback(
    async (formData, redirect = false) => {
      try {
        setState({
          ...state,
          error: undefined,
          loading: true,
        });

        handleRememberUsername(formData);

        const user = await loginBase({
          username: formData.username.toLowerCase(), // lowercase email for cognito
          password: formData.password,
        });

        if (user?.challengeName === 'SMS_MFA') {
          setState(currentState => ({
            ...currentState,
            loading: false,
            mfa: {
              user,
              redirect,
              form: {
                mfaUser: formData.username.toLowerCase(),
                mfaPass: formData.password,
              },
            },
          }));
          return;
        }

        return loginSuccess(redirect);
      } catch (error) {
        return loginFailure(error);
      }
    },
    [client, loginBase, loginSuccess, loginFailure, state]
  );

  // Login and redirect to link avant
  const loginToLink = useCallback(
    async formData => {
      if (!isNaN(formData.username)) {
        await login(formData);
        return;
      }
      try {
        login(formData, true);
      } catch (error) {
        loginFailure(error);
      }
    },
    [client, login, loginFailure, state]
  );

  const loginConfirm = useCallback(
    async code => {
      try {
        setState(currentState => ({
          ...currentState,
          error: undefined,
          loading: true,
        }));
        await Auth.confirmSignIn(state.mfa.user, code, 'SMS_MFA');

        return loginSuccess(state.mfa.redirect);
      } catch (error) {
        return loginFailure(error);
      }
    },
    [client, loginSuccess, loginFailure, state, setState]
  );

  const resendMfa = useCallback(async () => {
    try {
      setState(currentState => ({
        ...currentState,
        error: undefined,
      }));
      const user = await loginBase({
        username: state.mfa.form.mfaUser,
        password: state.mfa.form.mfaPass,
      });
      setState(currentState => ({
        ...currentState,
        mfa: {
          ...currentState.mfa,
          user,
        },
      }));
    } catch (error) {
      loginFailure(error);
    }
  }, [client, loginBase, loginFailure, state]);

  const verifyInvitationFlow = useCallback(
    (token, userData) => {
      setState(currentState => ({
        ...currentState,
        loading: true,
      }));
      return axios
        .post('/sso/invitation/verify', {
          token,
          dob: userData.dob,
          lastName: userData.lastName,
        })
        .catch(error => {
          console.log(error.response);
          if (error.response && error.response.status === 403) {
            return Promise.reject(
              'Verification failed. Please check your details.'
            );
          }
          return Promise.reject(error);
        })
        .then(response => response.data)
        .then(async credentials => {
          const newPassword = userData.password;
          const user = await loginBase(credentials);
          if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            await Auth.completeNewPassword(
              user,
              newPassword,
              user.challengeParam.requiredAttributes
            );
          }
          await Auth.signOut();
          return {
            username: credentials.username,
            password: newPassword,
          };
        })
        .then(login)
        .catch(error => {
          setState(currentState => ({
            ...currentState,
            error: error.message || error,
            loading: false,
          }));
        });
    },
    [login, loginBase]
  );

  const changePasswordBase = useCallback(
    async ({ oldPassword, newPassword }) => {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(user, oldPassword, newPassword);
      await logActivity({
        variables: { input: { type: 'PASSWORD_CHANGE' } },
        refetchQueries: [{ query: DrawerQuery }],
      });
    },
    [logActivity]
  );

  // Changes the user password
  const changePassword = useCallback(
    async ({ oldPassword, newPassword }) => {
      try {
        await changePasswordBase({ oldPassword, newPassword });

        window.location.assign('/oms/sso/changeSuccess');

        // TODO: add the navigation to the confirm page
      } catch (error) {
        setState(currentState => ({
          ...currentState,
          error: error.message,
        }));
      }
    },
    [changePasswordBase]
  );

  const forgotPassword = useCallback(
    async ({ email }) => {
      try {
        setState({
          ...state,
          loading: true,
          error: undefined,
        });

        const data = await Auth.forgotPassword(email);

        window.location.assign('/oms/sso/resetEmailSent', {
          state: {
            email: email.toLowerCase(),
          },
        });

        setState(currentState => ({
          ...currentState,
          forgotPasswordData: data,
          loading: false,
          error: undefined,
        }));
      } catch (error) {
        // default reset with email handler
        setState(currentState => ({
          ...currentState,
          error,
          loading: false,
        }));
      }
    },
    [state]
  );

  const resetPassword = useCallback(
    async ({ username, code, newPassword }) => {
      try {
        setState({
          ...state,
          loading: true,
          error: undefined,
        });

        const data = await Auth.forgotPasswordSubmit(
          username,
          String(code),
          newPassword
        );

        setState(currentState => ({
          ...currentState,
          resetPasswordData: data,
        }));
        window.location.assign('/oms/sso/setSuccess');
      } catch (error) {
        setState(currentState => ({
          ...currentState,
          error: error,
          loading: false,
        }));
      }
    },
    [state]
  );

  const customAuth = useCallback(
    async ({ token, sub }) => {
      try {
        setState({
          ...state,
          loading: true,
          error: undefined,
        });

        // Explicitly configure custom authentication here, just
        // in case, amplify doesn't pick it up automatically.
        // Auth.configure({
        //   authenticationFlowType: 'CUSTOM_AUTH',
        // });

        // Calling signIn() without password will trigger the custom authentication
        // flow
        const user = await Auth.signIn(sub);
        let data = undefined;
        if (user.challengeName === 'CUSTOM_CHALLENGE') {
          data = await Auth.sendCustomChallengeAnswer(user, token);
        } else {
          throw new Error('Custom authentication not set up');
        }
        await client.clearStore();
        const userData = await getUser();
        setState(currentState => ({
          ...currentState,
          userData,
          customChallengeData: { user, data },
        }));
        window.location.assign(`${process.env.DHF_BASE_URL}/oms`);
      } catch (error) {
        await client.resetStore();
        setState(currentState => ({
          ...currentState,
          error: error,
          loading: false,
        }));
        window.location.assign(`${process.env.DHF_BASE_URL}/oms`);
      }
    },
    [client, state]
  );

  const { getLocaleString } = useLocale();
  const verify = useCallback(
    async ({
      firstname,
      lastname,
      day,
      month,
      year,
      dob,
      username,
      password,
      membershipId,
      email,
    }) => {
      try {
        setState({
          ...state,
          loading: true,
          error: undefined,
        });

        const requiresBasicAuth = !isUndefined(password);

        const verification = {
          dob: `${year}-${month}-${day}`,
          membershipId: username || membershipId,
          lastName: lastname,
        };

        const auth = {
          auth: { username, password },
        };

        await axios
          .post('/conversion/verify', verification, requiresBasicAuth && auth)
          .then(response => {
            if (response.data.code === 'OK') {
              setState(currentState => ({
                ...currentState,
                error: undefined,
                loading: false,
              }));
              (async () => {
                window.location.assign(`/oms/sso/verification/step-2`, {
                  state: {
                    auth: {
                      username,
                      password,
                      verification,
                      dob,
                      year,
                      month,
                      day,
                      lastname,
                      email,
                    },
                  },
                });
              })();
            }
          });
      } catch (error) {
        let message = getConversionErrorMessage(error);
        // handle depandant error individually
        if (error.response.status === 403) {
          message = `Dependants on ${getLocaleString(
            'common.organisationName'
          )} policy are not currently able to register for access to online member services. The primary member can access this account.`;
        }

        setState(currentState => ({
          ...currentState,
          error: { message },
          loading: false,
        }));
      }
    },
    [state, getLocaleString]
  );

  const createLogin = useCallback(
    async ({
      email,
      mobile,
      password,
      username,
      oldPassword,
      verification,
      dob,
      year,
      month,
      day,
      lastname,
    }) => {
      try {
        setState({
          ...state,
          loading: true,
          error: undefined,
        });

        // check which flow, if user comes via reset he doesn't require basic auth
        const requiresBasicAuth = !isUndefined(username);

        const data = {
          dob: `${year}-${month}-${day}`,
          membershipId: username || verification.membershipId,
          lastName: verification.lastName,
          email: email.toLowerCase(),
          mobile,
          password,
          isRegistering: !requiresBasicAuth,
        };

        const auth = {
          auth: { username, password: oldPassword },
        };

        await axios
          .post('/conversion/convert', data, requiresBasicAuth && auth)
          .then(response => {
            if (
              response.data.code === 'OK' ||
              response.data.code === 'ACCEPTED'
            ) {
              setState(currentState => ({
                ...currentState,
                error: undefined,
                loading: false,
              }));

              // response details example
              // details: {
              //   username: 'panipih@mailinator.com',
              //   membershipId: '224624',
              //   personId: '35577',
              //   email: 'panipih@mailinator.com',
              //   emailVerified: false,
              //   mobile: '+61439562472',
              //   mobileVerified: false,
              //   peopleOnPolicy: 1,
              // },

              const details = response.data.details;

              // let's re-direct the user after he was converted based on attributes
              if (details.emailVerified === false) {
                // verify email first
                (async () => {
                  window.location.assign(
                    `/oms/sso/verification/success-new-email`,
                    {
                      state: {
                        details,
                      },
                    }
                  );
                })();
              } else {
                setState(currentState => ({
                  ...currentState,
                  loading: false,
                  error: undefined,
                }));

                (async () => {
                  window.location.assign(`/oms/sso/verification/success`, {
                    state: {
                      details,
                      password,
                    },
                  });
                })();
              }
            }
          });
      } catch (error) {
        const message = getConversionErrorMessage(error);
        setState(currentState => ({
          ...currentState,
          error: { message },
          loading: false,
        }));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state]
  );

  const verifyEmail = useCallback(token => {
    return axios.post('/sso/verifyEmail/', { token });
  }, []);

  const resetFormState = () => {
    setState(currentState => ({
      ...currentState,
      error: undefined,
      loading: false,
    }));
  };

  // const avant = useAvantSso({ getToken });

  // const registerDecorator = {
  //   state: register.state,
  //   actions: {
  //     ...register.actions,
  //     submit: data => {
  //       register.actions.submit(data);
  //       setState({});
  //     },
  //   },
  // };

  const clearMfa = useCallback(() => {
    setState(currentState => ({
      ...currentState,
      mfa: null,
      error: null,
    }));
  }, [setState]);

  const shouldLink = !!queryParams?.link;

  return (
    <AuthContext.Provider
      value={{
        ...state,
        rememberedUsername,
        login,
        loginConfirm,
        resendMfa,
        logout,
        getToken,
        getUser,
        // avant,
        checkForToken,
        forgotPassword,
        resetPassword,
        changePasswordBase,
        changePassword,
        customAuth,
        loginToLink,
        verify,
        createLogin,
        // register: registerDecorator,
        verifyEmail,
        verifyInvitationFlow,
        resetFormState,
        getErrorMessageByCode,
        getConversionErrorMessage,
        shouldLink,
        clearMfa,
      }}
      {...props}
    >
      {hasPermission() && children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
