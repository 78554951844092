import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Box } from 'rebass';
import { height, width } from 'styled-system';
import sanitizeSVG from '@mattkrick/sanitize-svg';
import sentry from '~lib/sentry';

class IconErrorBoundary extends React.Component {
  componentDidCatch(error, errorInfo) {
    console.error(error);
  }

  render() {
    return this.props.children;
  }
}

const checkForOriginalColorSchemaRequest = fn => props => {
  if (props.originalColorSchema) {
    return '';
  }

  return fn(props);
};

const Wrapper = styled(Box)`
  cursor: ${props => (props.clickable ? 'pointer' : 'default')};

  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  flex-shrink: 0;
  ${width}
  ${height}

  svg {
    ${width}
    ${height}
    fill: ${checkForOriginalColorSchemaRequest(
      props => props.fill || 'transparent'
    )};
    stroke: ${checkForOriginalColorSchemaRequest(
      props => props.stroke || 'transparent'
    )};
    g,
    path {
      stroke: ${checkForOriginalColorSchemaRequest(
        props => props.stroke || 'transparent'
      )};
      fill: ${checkForOriginalColorSchemaRequest(
        props => props.fill || 'transparent'
      )};
    }
  }

  svg[name='chevron-down'],
  svg[name='chevron-up'] {
    ${width}
    ${height}
    fill: ${props => props.fill || '#4a4a4a'};
    g,
    path {
      stroke: ${props => props.stroke || '#4a4a4a'};
      fill: ${props => props.fill || '#4a4a4a'};
    }
  }

  &::after {
    content: attr(data-content);
    position: absolute;
    left: 21px;
    top: 50%;
    font-size: 10px;
    width: 10px;
    height: 100%;
    line-height: 5px;
  }
`;

const Svg = ({ name }) => {
  const [svg, setSvg] = useState();

  useEffect(() => {
    fetch(`/icons/${name}.svg`)
      .then(result => result.blob())
      .then(blob => blob.text())
      .then(sanitizeSVG)
      .then(setSvg)
      .catch(error => {
        sentry(error);
        console.error(error);
      });
  }, []);
  return <Box dangerouslySetInnerHTML={{ __html: svg }} />;
};

const Icon = React.forwardRef(
  ({ name, originalColorSchema = false, clickable = false, ...props }, ref) => {
    return (
      <IconErrorBoundary>
        <Wrapper
          clickable={clickable}
          originalColorSchema={originalColorSchema}
          ref={ref}
          {...props}
        >
          <Svg name={name} fallback={<span></span>} />
        </Wrapper>
      </IconErrorBoundary>
    );
  }
);

export default Icon;
