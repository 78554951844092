import { darken, lighten } from 'polished';
import rebrand from './rebrand';

const { colors, ...otherRebrand } = rebrand;

const primary = colors.primary;
const secondary = colors.secondary;
const tertiary = secondary;

export default {
  loading: {
    minCardHeight: 100,
  },
  quickQuoteLinkColor: 'secondary',
  usualInvertedButtonStyle: 'secondaryInverted',
  quoteButtonStyle: 'secondary',
  memberLoginButtonStyle: 'primary',

  productCardButtonStyle: {
    hospital: 'primary',
    extras: 'primary',
  },
  dobConfirmButtonStyle: 'primary',
  calculateQuoteButtonStyle: 'primary',
  emailQuoteButtonStyle: 'primaryInverted',
  joinNowButtonStyle: 'primary',
  createAccountButtonStyle: 'primary',
  upgradeCoverButtonStyle: 'primaryInverted',
  viewMyCoverButtonStyle: 'primary',
  productSwitch: {
    active: 'secondary',
    inactive: 'secondaryInverted',
  },
  factsheetLink: 'primaryInverted',
  thinButton: {
    border: '2px solid transparent',
    borderRadius: '6px',
    padding: '7px 14px',
    fontSize: '13px',
    lineHeight: '22px',
    fontWeight: 'bold',
    textAlign: 'center',
    display: 'block',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
  },
  colors: {
    white: '#fff',
    get mafBannerLogo() {
      return this.white;
    },
    black: '#000',
    orange: primary,
    primary,
    orange2: '#ef8933',
    light: '#4a4a4a',
    blue: '#1d4b8d',
    secondary,
    tertiary,
    blue2: '#1e4b8d',
    secondary2: secondary,
    darkBlue: '#1d4b8d',
    borders: '#DFDEDE',
    borders2: 'rgba(255, 255, 255, 0.25)',
    borders3: '#dfdede',
    warning: '#f5a623',
    error: '#E31C12',
    iceBlue: '#eceded',
    green: '#569B07',
    dark: '#191f28',
    hospital: primary,
    extras: primary,
    text: {
      black: '#000',
      white: '#fff',
      dark: '#191f28',
      dark2: '#1a222d',
      dark3: '#A0A0A0',
      light: '#4a4a4a',
      light2: '#8B8B8B',
      light3: '#808080',
      light4: '#c0c0c0',
      light5: '#696969',
      light6: '#f6f6f6',
      light7: '#545454',
      footer: '#909090',
      neutral400: '#656565',
      neutral600: '#3B3B3B',
      neutral100: '#C9C9C9',
      blue: lighten(0.05, secondary),
      secondary: lighten(0.05, secondary),
      blue2: secondary,
      secondary2: secondary,
      blue3: '#445A7A',
    },
    background: {
      orange: primary,
      footer: secondary,
      primary: secondary,
      lightest: '#fff',
      light: '#F7F7F7',
      light2: '#f6f6f6',
      gray: '#eceded',
      lightGray: '#f8f8f8',
      dark: '#191f28',
      base: '#F4F4F4',
      red: 'red',
      transparentGrey: 'rgba(25, 31, 40, 0.25)',
      transparentGrey2: 'rgba(255, 255, 255, 0.25)',
      lightNeutral50: '#F9F9F9',
    },
    hover: {
      orange: darken(0.05, primary),
      primary: darken(0.1, primary),
      light: 'rgba(230, 103, 35, 0.4)',
      light2: '#f6f6f6',
    },
    alert: {
      success: {
        background: '#EBFED6',
        color: '#447E00',
      },
      info: {
        background: '#D7E7FF',
        color: '#2C539B',
      },
      error: {
        background: '#FFDDDD',
        color: '#E31C12',
      },
      warning: {
        background: '#fdefe5',
        color: primary,
      },
    },
    ...colors,
  },
  layout: {
    max: 1258,
  },
  zIndex: {
    navTabs: 10,
    nav: 120,
    navMobile: 100,
    menu: 110,
    burger: 120,
    timedLogout: 200,
  },
  padding: {
    paddingLeft: 20,
    paddingRight: 20,
    xs: {
      paddingLeft: 15,
      paddingRight: 15,
    },
  },
  containerWidths: {
    xs: '100%',
    sm: '100%',
    md: '100%',
    lg: '1200px',
    xl: '1200px',
  },
  space: [0, 4, 8, 16, 32, 64, 128, 256],
  fonts: {
    sans: '"Sharp Sans Display No1", "Helvetica Neue", Helvetica, Arial, sans-serif',
    body: '"Sharp Sans Display No1", "Helvetica Neue", Helvetica, Arial, sans-serif',
    heading:
      '"Sharp Sans Display No1", "Helvetica Neue", Helvetica, Arial, sans-serif',
    mono: 'Menlo, monospace',
  },
  fontSizes: [12, 13, 18, 21, 24, 32, 48],
  fontSizes2: [10, 20, 30, 40, 50],
  fontWeights: {
    body: 400,
    heading: 700,
    normal: 400,
    semibold: 600,
    bold: 700,
  },
  shadows: {
    small: '0 0 4px rgba(0, 0, 0, .125)',
    large: '0 0 24px rgba(0, 0, 0, .125)',
    text: '3px 4px 10px #ffffff5c',
  },
  ...otherRebrand,
};
