export const getAddress = (kleberAddress = {}) => {
  return {
    line1: kleberAddress.AddressLine,
    suburb: kleberAddress.Locality,
    postcode: kleberAddress.Postcode,
    state: kleberAddress.State,
  };
};

export const getAltAddress = (kleberAddress = {}) => ({
  line1: [
    kleberAddress.UnitNumber,
    kleberAddress.StreetNumber1,
    kleberAddress.AltStreetName,
    kleberAddress.AltStreetType,
  ].join(' '),
  suburb: kleberAddress.AltLocality,
  postcode: kleberAddress.AltPostcode,
  state: kleberAddress.AltState,
});
