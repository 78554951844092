import React, { useState, useEffect } from 'react';
import RTPopover, { ArrowContainer } from 'react-tiny-popover';
import { Box } from 'rebass';
import { inRange } from 'lodash';
import { useWindowScroll } from 'react-use';
export const usePopover = (initialState = false) => {
  const [isOpen, setOpen] = useState(initialState);
  const [openY, setOpenY] = useState(0);
  const { y } = useWindowScroll();

  const open = () => {
    setOpen(true);
    setOpenY(y);
  };
  const close = () => {
    setOpen(false);
  };
  const toggle = () => setOpen(currentState => !currentState);

  useEffect(() => {
    // close tooltip on scroll
    if (!inRange(openY, y - 50, y + 50)) {
      close();
    }
  }, [openY, y]);

  return [isOpen, { open, close, toggle }];
};

const Popover = ({
  children,
  position,
  content,
  theme,
  isSingleIcon,
  className,
  ...props
}) => {
  const [isOpen, actions] = usePopover(false);

  theme = theme || 'dark';

  content = (
    <div
      style={{
        position: 'relative',
        fontSize: '14px',
        padding: theme === 'dark' ? (isSingleIcon ? '15px' : '20px') : '0 10px',
        color: theme === 'dark' ? '#fff' : '#191f28',
        backgroundColor: theme === 'dark' ? 'rgba(0, 0, 0, 0.9)' : '#fff',
        boxShadow:
          theme === 'dark' ? 'none' : '2px 2px 7px 0 rgba(0, 0, 0, 0.3)',
        border: theme === 'dark' ? 'none' : '1px solid #191f28',
        borderRadius: theme === 'dark' ? (isSingleIcon ? '10px' : '0px') : '0',
        maxWidth: '260px',
        zIndex: 9000,
      }}
    >
      {content}
    </div>
  );
  // }

  return (
    <Box
      className={className}
      role="tooltip"
      onMouseOver={actions.open}
      onMouseOut={actions.close}
      onFocus={actions.open}
      onBlur={actions.close}
    >
      <RTPopover
        isOpen={isOpen}
        transitionDuration={0}
        position={position}
        content={({ position, targetRect, popoverRect }) => (
          <ArrowContainer
            position={position}
            targetRect={targetRect}
            popoverRect={popoverRect}
            arrowColor={theme === 'dark' ? 'rgba(0, 0, 0, 1)' : '#fff'} // need the arrow shape to be solid color
            arrowSize={isSingleIcon ? 8 : 14}
            arrowStyle={{ marginBottom: '1px', zIndex: '9999' }}
          >
            {content}
          </ArrowContainer>
        )}
        {...props}
      >
        {React.Children.only(children)}
      </RTPopover>
    </Box>
  );
};

export default Popover;
