import { graphql, useStaticQuery } from 'gatsby';
import { useMemo } from 'react';
import { rewriteSanityUrl } from '~lib/sanity/sanityUtils';
import { merge } from 'lodash';

const BRANDING_QUERY = graphql`
  query BrandingQuery {
    sanityBranding {
      _id
      fontUrls
      appleStoreUrl
      googleStoreUrl
      logo {
        asset {
          url
          id
        }
      }
      altLogo {
        asset {
          url
          id
        }
      }
    }
  }
`;

export const useBrandingBase = sanityBranding => {
  return useMemo(() => {
    return {
      ...sanityBranding,
      logo: sanityBranding?.logo
        ? merge(sanityBranding?.logo, {
            asset: {
              url: rewriteSanityUrl(sanityBranding?.logo?.asset?.url),
            },
          })
        : undefined,
    };
  }, [sanityBranding]);
};

export default () => {
  const { sanityBranding } = useStaticQuery(BRANDING_QUERY);
  return useBrandingBase(sanityBranding);
};
