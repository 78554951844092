import { darken, lighten } from 'polished';

const primary = '#0D3050';
const secondary = '#F37021';
const tertiary = '#0194D3';

export default {
  loading: {
    minCardHeight: 100,
  },
  quickQuoteLinkColor: 'primary',
  usualInvertedButtonStyle: 'primaryInverted',
  productModeDetailsButtonStyle: 'primaryRound',
  quoteButtonStyle: 'secondary',
  memberLoginButtonStyle: 'primary',
  productCardButtonStyle: {
    hospital: 'primary',
    extras: 'tertiary',
  },
  dobConfirmButtonStyle: 'secondary',
  calculateQuoteButtonStyle: 'secondary',
  emailQuoteButtonStyle: 'secondaryInverted',
  joinNowButtonStyle: 'secondary',
  productSwitch: {
    active: 'primaryThin',
    inactive: 'primaryInverted',
  },
  createAccountButtonStyle: 'secondary',
  upgradeCoverButtonStyle: 'primaryInverted',
  viewMyCoverButtonStyle: 'primary',
  factsheetLink: 'primaryInverted',
  thinButton: {
    border: '2px solid transparent',
    borderRadius: '6px',
    padding: '7px 14px',
    fontSize: '13px',
    lineHeight: '22px',
    fontWeight: 'bold',
    textAlign: 'center',
    display: 'block',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
  },
  colors: {
    mafBannerLogo: primary,
    activeOmsMenuItem: secondary,
    white: '#fff',
    black: '#000',
    orange: primary,
    primary,
    orange2: darken(0.05, primary),
    primary2: darken(0.05, primary),
    light: '#4a4a4a',
    blue: secondary,
    blue2: secondary,
    secondary,
    tertiary,
    darkBlue: darken(0.05, secondary),
    borders: '#DFDEDE',
    borders2: 'rgba(255, 255, 255, 0.25)',
    borders3: '#dfdede',
    warning: '#f5a623',
    error: '#E31C12',
    iceBlue: '#eceded',
    green: '#569B07',
    dark: '#191f28',
    hospital: primary,
    extras: tertiary,
    text: {
      white: '#fff',
      black: '#000',
      dark: '#191f28',
      dark2: '#1a222d',
      dark3: '#A0A0A0',
      light: '#4a4a4a',
      light2: '#8B8B8B',
      light3: '#808080',
      light4: '#c0c0c0',
      light5: '#696969',
      light6: '#f6f6f6',
      light7: '#545454',
      footer: '#909090',
      neutral400: '#656565',
      neutral600: '#3B3B3B',
      neutral100: '#C9C9C9',
      menu: primary,
      activeMenu: secondary,
      blue: lighten(0.05, primary),
      secondary: lighten(0.05, primary),
      blue2: primary,
      secondary2: primary,
      blue3: '#445A7A',
    },
    background: {
      orange: primary,
      primary,
      footer: primary,
      lightest: '#fff',
      light: '#F7F7F7',
      light2: '#f6f6f6',
      gray: '#eceded',
      dark: '#191f28',
      base: '#F4F4F4',
      red: 'red',
      transparentGrey: 'rgba(25, 31, 40, 0.25)',
      transparentGrey2: 'rgba(255, 255, 255, 0.25)',
      lightNeutral50: '#F9F9F9',
    },
    hover: {
      orange: primary,
      primary: primary,
      light: lighten(0.3, primary),
      light2: '#f6f6f6',
    },
    alert: {
      success: {
        background: '#EBFED6',
        color: '#447E00',
      },
      info: {
        background: '#D7E7FF',
        color: '#2C539B',
      },
      error: {
        background: '#FFDDDD',
        color: '#E31C12',
      },
      warning: {
        background: '#fdefe5',
        color: primary,
      },
    },
  },
  layout: {
    max: 1258,
  },
  zIndex: {
    navTabs: 10,
    nav: 120,
    navMobile: 100,
    menu: 110,
    burger: 120,
    timedLogout: 200,
  },
  padding: {
    paddingLeft: 20,
    paddingRight: 20,
    xs: {
      paddingLeft: 15,
      paddingRight: 15,
    },
  },
  containerWidths: {
    xs: '100%',
    sm: '100%',
    md: '100%',
    lg: '1200px',
    xl: '1200px',
  },
  space: [0, 4, 8, 16, 32, 64, 128, 256],
  fonts: {
    sans: '"Proxima Nova", Arial',
    body: '"Proxima Nova", Arial',
    heading: '"Proxima Nova", Arial',
    mono: 'Menlo, monospace',
  },
  fontSizes: [12, 13, 18, 21, 24, 32, 48],
  fontSizes2: [10, 20, 30, 40, 50],
  fontWeights: {
    body: 400,
    heading: 700,
    normal: 400,
    semibold: 600,
    bold: 700,
  },
  shadows: {
    small: '0 0 4px rgba(0, 0, 0, .125)',
    large: '0 0 24px rgba(0, 0, 0, .125)',
  },
  buttons: {
    'white-round': {
      color: primary,
      ':hover': {
        color: primary,
      },
    },
  },
};
