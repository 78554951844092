import React from 'react';
import { StandardText } from './typography';

const PhoneNumber = ({ phone, render, ...props }) => {
  if (render) {
    return render({ phone });
  }
  return (
    <StandardText
      css={{
        textDecoration: 'none',
      }}
      clickable
      as="a"
      href={`tel:${phone}`}
      {...props}
    >
      {phone}
    </StandardText>
  );
};

export default PhoneNumber;
