import { useEffect, useRef } from 'react';

export default (ref, focus) => {
  const defaultRef = useRef();

  ref = ref || defaultRef;

  useEffect(() => {
    if (ref && focus && ref.current) {
      ref.current.focus();
    }
  }, [focus, ref]);

  return innerRef => {
    if (typeof ref === 'function') {
      ref(innerRef);
    }

    if (ref) {
      ref.current = innerRef;
    }
  };
};
