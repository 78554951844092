import CallUsNumber from '~common/molecules/CallUsNumber';
import React from 'react';
import isUndefined from 'lodash/isUndefined';

export const getErrorMessageByCode = code => {
  return {
    401: (
      <>
        We are unable to verify your details. Please try again or call{' '}
        <CallUsNumber inline bold color="error" /> for assistance.
      </>
    ),

    403: 'Login failed - Your username and/or password do not match our records.',
    // normally handled via redirect
    412: error =>
      error.response.data.code === 'PRECONDITION_FAILED_USERNAME' ? (
        `Username is already in use`
      ) : (
        <>
          This policy has already been registered, please login using your email
          address and password. If you did not register this account, please
          request access from the primary account holder or call us for help on{' '}
          <CallUsNumber inline bold color="error" />.
        </>
      ),

    429: error =>
      `There have been too many failed login attempts. Please try again in ${Math.floor(
        error.response.headers['retry-after'] / 60
      )} minutes.`,
    500: 'Something went wrong - We are currently experiencing issues processing this request. We are working hard to fix things, so please try again later.',
  }[code];
};

export const getErrorMessageFromDetails = ({ type } = {}) => {
  let message;
  if (type === 'validateMembershipId') {
    message = 'This membership number is not recognised';
  }
  return message;
};

// error messages for conversion process / user enters membership number instead of email
export const getConversionErrorMessage = error => {
  if (!isUndefined(error.response.status)) {
    let message =
      getErrorMessageByCode(error.response.status) ||
      getErrorMessageFromDetails(error.response.data.details);
    message = typeof message === 'function' ? message(error) : message;
    return message || error.response.data.message;
  }
  return error.response.data.message;
};
