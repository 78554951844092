import React from 'react';
import { VIEWS } from '~OMS/contact-us/useContactUsForms';
import Show from '~common/atoms/Show';
import LightBox from '~common/atoms/LightBox';
import EmailUsForm from '~OMS/contact-us/EmailUsForm';
import EmailUsConfirmation from '~OMS/contact-us/EmailUsConfirmation';

const EmailUsLightBox = ({
  view,
  initialValues,
  onClose,
  onSubmit,
  loading,
  isAnomUser,
}) => {
  return (
    <LightBox
      centerH
      centerV={[VIEWS.EMAIL_US_CONFIRM].includes(view)}
      onClose={onClose}
      visible={[VIEWS.EMAIL_US_CONFIRM, VIEWS.EMAIL_US].includes(view)}
    >
      <Show if={view === VIEWS.EMAIL_US}>
        {() => {
          return (
            <EmailUsForm
              initialValues={initialValues}
              isAnomUser={isAnomUser}
              loading={loading}
              onSubmit={onSubmit}
              onCancel={onClose}
            />
          );
        }}
      </Show>

      <Show if={view === VIEWS.EMAIL_US_CONFIRM}>
        {() => <EmailUsConfirmation onClose={onClose} />}
      </Show>
    </LightBox>
  );
};

export default EmailUsLightBox;
