export const SINGLE = 'SINGLE';
export const COUPLE = 'COUPLE';
export const FAMILY = 'FAMILY';
export const SINGLE_PARENT = 'SINGLE_PARENT';

// this one can not be selected on QQ page
export const SINGLE_PARENT_EXTENDED = 'SINGLE_PARENT_EXTENDED';

export default [
  { value: SINGLE, label: 'Single', icon: 'status-single' },
  { value: COUPLE, label: 'Couple', icon: 'status-couple' },
  { value: FAMILY, label: 'Family', icon: 'status-family' },
  {
    value: SINGLE_PARENT,
    label: 'Single Parent',
    icon: 'status-single-parent',
  },
];
