import { find, path, pipe, whereEq } from 'lodash/fp';
import { chainedPath, pathOr } from '~lib/util';
import * as dates from '~lib/dates';
import { isInPast } from '~lib/dates';
import numberPrecision from 'number-precision';

export const PERSON_ROLE = {
  DEPENDANT: 'DEPENDANT',
  PARTNER: 'PARTNER',
  MEMBER: 'MEMBER',
};

export const getLoggedInUser = data => {
  return pipe(
    pathOr([], 'oms.membership.persons'),
    find(
      whereEq({
        id: path('oms.user.personId')(data),
      })
    )
  )(data);
};

export const getSortedByPerson = personPath => data => {
  const clonedData = [...data];
  clonedData.sort((objectA, objectB) => {
    const personA = personPath ? path(personPath)(objectA) : objectA;
    const personB = personPath ? path(personPath)(objectB) : objectB;
    const a =
      personA.role === PERSON_ROLE.MEMBER
        ? 0
        : personA.role === PERSON_ROLE.PARTNER
        ? 1
        : 2;
    const b =
      personB.role === PERSON_ROLE.MEMBER
        ? 0
        : personB.role === PERSON_ROLE.PARTNER
        ? 1
        : 2;

    return a === b ? dates.compare(personA.dob, personB.dob) : a - b;
  });
  return clonedData;
};

export const getSortedPersons = pipe(
  pathOr([], 'oms.membership.persons'),
  getSortedByPerson('')
);
export const getMembershipAddress = path('oms.membership.membershipAddress');
export const getMembershipAddressLine = path(
  'oms.membership.membershipAddressLine'
);

export const getPerson = chainedPath('oms.person');
export const getMembership = chainedPath('oms.membership');

export const isPaymentInArrears = pipe(
  path('oms.payments.paidToDate'),
  isInPast
);

export const getExtrasProduct = path('oms.membership.plan.extrasProduct');
export const getExtrasProductId = pipe(getExtrasProduct, path('id'));

export const isPayrollPayment = path('oms.membership.isPayroll');

export const shouldKeepPrivate = path('oms.person.isPrivate');

export const getHospitalProduct = path('oms.membership.plan.hospitalProduct');
export const isExtrasOnly = data =>
  path('oms.membership.plan.type')(data) === 'EXTRAS_ONLY';

export const isHospitalOnly = data =>
  path('oms.membership.plan.type')(data) === 'HOSPITAL_ONLY';

export const isBoth = data => path('oms.membership.plan.type')(data) === 'BOTH';

export const isS2IMembership = data =>
  path('oms.membership.site.siteCode')(data) === 'YDR';

export const isUpgradeCoverEligible = data => {
  return (
    isS2IMembership(data) && getPerson(data)('role') === PERSON_ROLE.MEMBER
  );
};

export const isSubsidisedExtrasMembership = data => {
  return path('oms.membership.site.siteCode')(data) === 'YDR2';
};

export const isChangeCoverAllowed = data =>
  process.env.BRAND_NAME === 'DHF' &&
  !isS2IMembership(data) &&
  !isSubsidisedExtrasMembership(data);

export const isBankPayment = data =>
  path('oms.payments.debitAccount.type')(data) === 'BANK';

export const isCreditCardPayment = data =>
  !!(
    path('oms.payments.debitAccount')(data) &&
    path('oms.payments.debitAccount.type')(data) !== 'BANK'
  );

export const getPartiallySubsidisedSavingAmount = data => {
  return numberPrecision.plus(
    Number(path('oms.membership.rate.extras.subsidy')(data)),
    Number(path('oms.membership.rate.hospital.subsidy')(data))
  );
};

export const isPartiallySubsidisedExtras = data => {
  if (!isSubsidisedExtrasMembership(data)) {
    return false;
  }
  const savingAmount = getPartiallySubsidisedSavingAmount(data);
  const grossExtras = numberPrecision.minus(
    Number(path('oms.membership.rate.extras.gross')(data)),
    Number(path('oms.membership.rate.extras.discount')(data))
  );
  return savingAmount < grossExtras;
};

export const isFullySubsidisedExtras = data => {
  return (
    isS2IMembership(data) ||
    (isSubsidisedExtrasMembership(data) && !isPartiallySubsidisedExtras(data))
  );
};

export const isAvantEmployee = data => {
  return path('oms.membership.eligibility.reasonCode')(data) === 'EmpAv';
};

export const isReferFriendEligible = data => {
  return path('oms.membership.canReferMember')(data);
};
