export default {
  xs: '0',
  tinyPhone: '480px',
  sm: '768px', //768px
  md: '992px', //992px,
  tabletP: '768px',
  tabletL: '1024px',
  lg: '1200px', //1200px
  xl: '1440px', //1440px
  xxl: '2000px',
};
