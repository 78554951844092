import scrollbarWidth from '~lib/scrollbarWidth';
import breakpoints from '~lib/breakpoints';
import defaultTheme from '~lib/theme/defaultTheme';
import { createBreakpointArray, createMediaQueryObject } from '~lib/util';
import dhfRebrand from '~lib/theme/rebrand';

export default () => {
  return {
    ...defaultTheme,
    scrollbarWidth: scrollbarWidth(),
    breakpoints: createBreakpointArray(breakpoints),
    mq: createMediaQueryObject(breakpoints),
    dhfRebrand,
  };
};
