import { cloneDeep } from 'lodash';
import { traverseObject } from '~lib/util/index';

const PARAM_DETECTOR_REGEXP = /\{\{(.+)\}\}/g;

const applyInterpolation = (value = '', params) => {
  return value
    .split(' ')
    .map(word => {
      const matched = PARAM_DETECTOR_REGEXP.exec(word);
      return matched ? params[matched[1]] || word : word;
    })
    .join(' ');
};

export const interpolateTextBlock = (textBlock, params) => {
  const clonedBlock = cloneDeep(textBlock);

  traverseObject(clonedBlock, (propName, propValue, parent) => {
    if (typeof propValue === 'string') {
      parent[propName] = applyInterpolation(propValue, params);
    }
  });

  return clonedBlock;
};
