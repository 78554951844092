import React, { useEffect, useCallback, useRef } from 'react';
import { Flex, Box } from 'rebass';
import styled from '@emotion/styled';
import { withTheme } from '@emotion/react';
import { css } from '@emotion/react';

import Icon from '~common/atoms/Icon.js';
import { StandardHeading } from './typography';
import Overlay from './Overlay';

import { keyframes } from '@emotion/react';

const animations = {
  scaleIn: keyframes`
   from {
      transform: scale(0);
   }
   to {
   transform: scale(1);
   }
`,
  moveInFromTop: keyframes`
   from {
      transform: translateY(-100%);
   }
   to {
   transform: translateY(0);
   }
`,
};

const StyledLightBox = styled(Box)`
  position: relative;
  background: ${props => props.theme.colors.white};
  height: ${props => props.height};
  width: ${props => props.width};
  overflow-y: auto;
  ${props => (props.centerV ? `display: flex; align-items: center` : '')};
  min-width: ${props => props.minWidth || props.width};
  min-height: ${props => props.minHeight || props.height};
  animation: ${props => animations[props.animation]} 300ms
    cubic-bezier(0.645, 0.045, 0.355, 1);
`;

const StyledIcon = styled(Icon)`
  position: absolute;
  right: 30px;
  top: 30px;
  cursor: pointer;
  transition: color 300ms ease-in-out;
`;

const StyledContentContainer = styled(Box)`
  width: 100%;
  ${props => (props.centerH ? `display: flex; justify-content: center;` : '')};
  height: ${props => props.height};
`;

const LightBox = ({
  title,
  children,
  visible,
  onClose,
  theme,
  centerH,
  centerV,
  className,
  width = '100%',
  height = '100%',
  showShade = true,
  showHeader = true,
  animation = 'scaleIn',
  showCloseIcon = true,
  disableOverlayClick = false,
  ...props
}) => {
  const toggleLightBoxRef = useRef();

  useEffect(() => {
    if (toggleLightBoxRef?.current) {
      toggleLightBoxRef.current(visible);
    }
  }, [visible]);

  const handleClose = useCallback(() => {
    if (onClose) {
      onClose();
    } else {
      toggleLightBoxRef?.current(false);
    }
  }, [onClose]);

  return (
    <Overlay
      showShade={showShade}
      grabToggler={toggle => {
        toggleLightBoxRef.current = toggle;
      }}
      onOverlayClick={
        !disableOverlayClick &&
        (() => {
          handleClose();
        })
      }
    >
      {() => {
        return (
          <StyledLightBox
            animation={animation}
            width={width}
            height={height}
            centerV={centerV}
            className={className}
            theme={theme}
            p={{
              xs: 20,
              tinyPhone: 40,
            }}
            {...props}
          >
            {showHeader && (
              <Flex
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                mb={theme.space[8]}
              >
                {title ? (
                  <StandardHeading fontSize={theme.fontSizes2[2]}>
                    {title}
                  </StandardHeading>
                ) : (
                  <span />
                )}
                {showCloseIcon && (
                  <StyledIcon
                    data-id="close-lightbox-btn"
                    onClick={handleClose}
                    stroke={theme.colors.black}
                    name="close"
                    height={{
                      xs: 20,
                      tinyPhone: 30,
                    }}
                    width={{
                      xs: 20,
                      tinyPhone: 30,
                    }}
                    css={css`
                      ${theme.mq.tinyPhoneInverted} {
                        right: 10px;
                        top: 10px;
                      }
                    `}
                  />
                )}
              </Flex>
            )}

            <StyledContentContainer
              height={showHeader ? 'auto' : '100%'}
              centerV={centerV}
              centerH={centerH}
            >
              {children}
            </StyledContentContainer>
          </StyledLightBox>
        );
      }}
    </Overlay>
  );
};

export default withTheme(LightBox);
