import React from 'react';

export const WithFieldAdapter = ControlComponent => {
  return ({ field, ...props }) => {
    const adaptedOnChange = (value, evt) => {
      if (value && value.target) {
        return field.onChange(value);
      }

      return field.onChange({
        target: {
          value: value,
          name: field.name,
        },
      });
    };

    return (
      <ControlComponent
        field={{
          ...field,
          onChange: adaptedOnChange,
        }}
        {...props}
      />
    );
  };
};
