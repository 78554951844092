export const rewriteSanityUrl = (url, origin) => {
  if (!url) {
    return null;
  }

  /// some images come relative paths ????
  if (typeof url === 'string' && !url.includes('http')) {
    return url;
  }

  try {
    origin = new URL(origin || process.env.DHF_BASE_URL);
    url = new URL(url);
    if (url.origin === origin.origin) {
      return `${url.pathname}${url.search}`;
    }
    url.protocol = origin.protocol;
    url.host = origin.host;
    return url.href;
  } catch (e) {
    console.log(e);
  }
  return url;
};

export const getFileUrl = id => {
  const path = `/files/${process.env.SANITY_PROJECT_ID}/${process.env.SANITY_DATASET}/${id}`;
  return rewriteSanityUrl(
    new URL(path, process.env.SANITY_FILES_CDN_URL || process.env.DHF_BASE_URL),
    process.env.SANITY_FILES_CDN_URL
  );
};

export const getImageUrl = id => {
  const path = `/images/${process.env.SANITY_PROJECT_ID}/${process.env.SANITY_DATASET}/${id}`;
  return rewriteSanityUrl(
    new URL(
      path,
      process.env.SANITY_IMAGES_CDN_URL || process.env.DHF_BASE_URL
    ),
    process.env.SANITY_IMAGES_CDN_URL
  );
};
