import React from 'react';
import { VIEWS } from '~OMS/contact-us/useContactUsForms';
import Show from '~common/atoms/Show';
import RequestCallBackForm from '~OMS/contact-us/RequestCallBackForm';
import ModalConfirmation from '~common/molecules/ModalConfirmation';
import LightBox from '~common/atoms/LightBox';

const RequestCallbackLightBox = ({
  view,
  confirmationMessage,
  onClose,
  onSubmit,
  loading,
  isAnomUser,
  initialValues,
}) => {
  return (
    <LightBox
      data-id="request-callback"
      centerH
      centerV={[VIEWS.REQUEST_CALLBACK_CONFIRM].includes(view)}
      onClose={onClose}
      visible={[
        VIEWS.REQUEST_CALLBACK,
        VIEWS.REQUEST_CALLBACK_CONFIRM,
      ].includes(view)}
    >
      <Show if={view === VIEWS.REQUEST_CALLBACK}>
        {() => {
          return (
            <RequestCallBackForm
              initialValues={initialValues}
              isAnomUser={isAnomUser}
              loading={loading}
              onSubmit={onSubmit}
              onCancel={onClose}
            />
          );
        }}
      </Show>

      <Show if={view === VIEWS.REQUEST_CALLBACK_CONFIRM}>
        {() => (
          <ModalConfirmation
            onConfirm={onClose}
            title="Thanks, talk soon!"
            message={confirmationMessage}
          />
        )}
      </Show>
    </LightBox>
  );
};

export default RequestCallbackLightBox;
