import { getFileUrl, getImageUrl } from '~lib/sanity/sanityUtils';

export default ({ contactDetails = {} }) => ({
  common: {
    requestCallbackUrl: 'https://doctorshealthfund.as.me/callbackrequest',
    memberLoginButton: 'Member login',
    organisationName: contactDetails.organisationName,
    logoUrl: getImageUrl('735d3fc4e09191bff5efc4e9d0fa174525d6cb14-129x53.svg'),
    googleMapsLocation:
      'https://www.google.com/maps/place/201+Sussex+St,+Sydney+NSW+2000/@-33.8706492,151.2028797,1007m/data=!3m1!1e3!4m5!3m4!1s0x6b12ae3be254d871:0x84e57eeef823bc33!8m2!3d-33.8712161!4d151.203816',
  },
  oms: {
    loginPage: {
      heading: 'Online Member Services',
    },
    payments: {
      directDebitServiceAgreementPdf:
        '/files/Direct_Debit_Service_Agreement.pdf',
    },
    claims: {
      formPdf: getFileUrl('3e5073373d26492fee2ca2a7135e362226a4fa10.pdf'),
      appStoreUrl:
        'https://itunes.apple.com/au/app/doctors-health-fund/id741965270?mt=8',
      playStoreUrl:
        'https://play.google.com/store/apps/details?id=cdaa.mobile.doctorshealthfund',
      approvalForm: getFileUrl('6c8e3e04cde3bace0b4fb1fb6b17bdae7ae28466.pdf'),
    },
    upgradeCover: {
      termsAndConditionsPdf: getFileUrl(
        '128df5dca73134efd53987690089b33b2eff8255.pdf'
      ),
    },
    common: {
      factSheetUrl: getFileUrl('318b4d697c6f7f5cb9fdd28f7818548020652d3e.pdf'),
    },
  },
  maf: {
    excludeAbdAndLhcDisclaimer:
      'This quote does not include any applicable youth discount or lifetime heath cover loading and may differ from your final premium. We will communicate your final premium in your welcome pack which will be emailed to you within 1 business day of joining.',
    payments: {
      directDebitServiceAgreementPdf:
        '/files/Direct_Debit_Service_Agreement.pdf',
    },
  },
});
