import toHast from 'mdast-util-to-hast';
import toHtml from 'hast-util-to-html';
import sanitizeHtml from '~lib/sanitizeHtml';

export default function plugin({ sanitize = {}, ...options } = {}) {
  const settings = options || {};
  const handlers = settings.handlers || {};

  this.Compiler = function compiler(node, file) {
    const root = node && node.type && node.type === 'root';
    const hast = toHast(node, {
      allowDangerousHtml:
        options.allowDangerousHtml != null ? options.allowDangerousHtml : true,
      handlers: handlers,
    });

    if (file.extname) {
      file.extname = '.html';
    }

    let result = toHtml(hast, {
      allowDangerousHtml: true,
      ...settings,
    });
    result = sanitizeHtml(result, sanitize);

    // Add an eof eol.
    if (root && result.charAt(result.length - 1) !== '\n') {
      result += '\n';
    }

    return result;
  };
}
