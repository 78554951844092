import React from 'react';
import ModalConfirmation from '~common/molecules/ModalConfirmation';

const EmailUsConfirmation = ({ onClose, ...props }) => {
  return (
    <ModalConfirmation
      onConfirm={onClose}
      title="Thanks for your enquiry!"
      message="Expect a reply from one of our friendly staff within 24 hours."
      {...props}
    />
  );
};

export default EmailUsConfirmation;
