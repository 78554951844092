import React, { useCallback, useState, useEffect } from 'react';
import { withTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Box } from 'rebass';
import Icon from '~common/atoms/Icon';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';

const fontSize = props => css`
  font-size: ${props.theme.fontSizes[props.fontSize || 3]}px;
`;

export const selectStyles = props => css`
  select {
    color: ${props => props.theme.colors.black};
    font-weight: 500;
    z-index: 1;
    appearance: none;
    outline: none;
    ${fontSize(props)}
    padding: 7px 30px 7px 10px;
    background: ${props.theme.colors.white};
    width: 100%;
    border: none;
    text-indent: 1px;
    text-overflow: '';
    cursor: pointer;
    option {
      padding: 30px;
    }
  }
`;

const StyledDropDown = styled(Box)`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  border-style: solid;
  border-width: 1px;
  border-color: ${props => props.theme.colors.neutral100};
  ${selectStyles};
`;

const StyledIcon = styled(Icon)`
  ${fontSize};
  right: 8px;
  top: 50%;
  position: absolute;
  transform: translateY(-34%);
  z-index: 2;
  pointer-events: none;
`;

const Option = ({ children, value, ...props }) => (
  <option {...props} value={value}>
    {children}
  </option>
);

Option.propTypes = {
  value: PropTypes.any.isRequired,
  children: PropTypes.any.isRequired,
};

const DropDown = ({
  theme,
  children,
  onChange,
  value: selected,
  placeholder,
  className,
  round = true,
  name,
  onBlur,
  ...props
}) => {
  const [selectedValue, setSelected] = useState('');
  const handleOptionClick = useCallback(
    evt => {
      evt.preventDefault();
      const { value } = evt.target;
      if (onChange) {
        // controlled mode
        onChange(value);
      } else {
        // uncontrolled mode
        setSelected(value);
      }
    },
    [onChange]
  );

  useEffect(() => {
    setSelected(selected);
  }, [selected]);

  return (
    <StyledDropDown
      round={round}
      className={className}
      theme={theme}
      {...props}
    >
      <select
        data-value={selected}
        onChange={handleOptionClick}
        value={selectedValue}
        onBlur={onBlur}
        name={name}
      >
        {placeholder && <Option value="">{placeholder}</Option>}
        {children}
      </select>
      <StyledIcon
        fill={theme.colors.primary}
        stroke={theme.colors.primary}
        height="17px"
        width="17px"
        name="chevron-down"
      />
    </StyledDropDown>
  );
};

DropDown.defaultProps = {
  value: '',
  round: true,
};

DropDown.propTypes = {
  round: PropTypes.bool,
  value: PropTypes.any,
  onChange: PropTypes.func,
  theme: PropTypes.object.isRequired,
};

const ThemedDropDown = withTheme(DropDown);

export { Option, ThemedDropDown as DropDown };
export default ThemedDropDown;
