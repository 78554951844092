import React from 'react';
import PhoneNumber from '../atoms/PhoneNumber';
import useContactDetails from '~lib/hooks/sanity/useContactDetails';

const CallUsNumber = ({ icon, ...props }) => {
  const sanityContactDetails = useContactDetails();
  return <PhoneNumber {...props} phone={sanityContactDetails.phone} />;
};

export default CallUsNumber;
