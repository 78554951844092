import { useState, useCallback } from 'react';

const useModalState = initial => {
  const [modalState, setModalState] = useState(initial);
  return {
    modalState,
    setModalState,
    toggleModal: useCallback(() => setModalState(value => !value), []),
    openModal: useCallback(() => setModalState(true), []),
    closeModal: useCallback(() => setModalState(false), []),
  };
};

export default useModalState;
