export default ({ contactDetails }) => {
  const { phone, organisationName } = contactDetails || {};
  const phoneNoSpaces = contactDetails.phone.replace(/\s+/g, '');
  return {
    oms: {
      claims: {
        terms: [
          'I agree to retain the original receipts for 6 months for audit purposes',
          'I confirm that these services are not claimable from a third party',
          'All information provided in support of each claim is true and complete',
          `All patients’ personal information has been disclosed with their consent, and all patients are aware of the ${organisationName} Privacy Policy`,
          `I authorise, and have the consent of the patient to authorise, ${organisationName} to contact provider(s) and to access any information, including health information, needed to process this claim`,
          `I acknowledge that each claim is subject to ${organisationName}’s Fund Rules and will comply with any reasonable requests made under those rules`,
        ],
      },
      errorPage: {
        loading: `Loading, please wait...`,
        suspended: `This membership has been suspended. Please call [${phone}](tel:${phoneNoSpaces}) for more information.`,
        terminated: `This membership has been terminated. Please call [${phone}](tel:${phoneNoSpaces}) for more information.`,
        personInactive: `This membership person is not active. Please call [${phone}](tel:${phoneNoSpaces}) for more information.`,
        maintenance: `
Online Member Services is currently unavailable while we upgrade our services.

We apologise for any inconvenience that this may cause, please try again later.

- Call us on [${phone}](tel:${phoneNoSpaces})
- Email us at [${contactDetails.email}](${contactDetails.email})
`,
        hambsError: {
          UNAUTHORISED_SITE_CODE: `
The credentials entered are not valid for this website.

Please contact us on [${phone}](tel:${phoneNoSpaces}) for support.
`,
        },
        error: `
We are currently experiencing issues processing this request.

We are working hard to fix things, so please try again later.
`,
      },
    },
  };
};
