import React from 'react';

import sentry from '~lib/sentry';
import ErrorPage from 'components/Elements/ErrorPage';

class SentryErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    sentry(error, errorInfo);
  }

  render() {
    if (this.state.error) {
      return (
        <ErrorPage
          logo={true}
          requestId={true}
          actions={{ home: false, logout: false }}
          icon={true}
          type="error"
          error={this.state.error}
        />
      );
    }

    return this.props.children;
  }
}

export default SentryErrorBoundary;
