const styles = {
  root: {
    fontFamily: 'body',
    lineHeight: 'body',
    fontWeight: 'body',
  },
  h1: {
    color: 'primary',
    fontFamily: 'heading',
    lineHeight: 'heading',
    fontWeight: 'heading',
    fontSize: [null, 11, null, null, null, 13, null, null, null],
  },
  h2: {
    color: 'primary',
    fontFamily: 'heading',
    lineHeight: 'heading',
    fontWeight: 'heading',
    fontSize: [null, 9, null, null, null, 12, null, null, null],
  },
  h3: {
    color: 'primary',
    fontFamily: 'heading',
    lineHeight: 'heading',
    fontWeight: 'heading',
    fontSize: [null, 5, null, null, null, 7, null, null, null],
  },
  p: {
    color: 'text',
    fontFamily: 'body',
    fontWeight: 'body',
    lineHeight: 'body',
  },
  a: {
    color: 'primary',
  },
};

export default styles;
