const { setConfig } = require('react-hot-loader');

setConfig({
  pureSFC: true,
  trackTailUpdates: false,
});

export const onServiceWorkerUpdateReady = () => {
  console.info(`
    ******************************************************************************
    * The application has been updated. Reloading to display the latest version. *
    ******************************************************************************`);

  if (typeof window !== 'undefined') {
    window.location.reload(true);
  }
};
