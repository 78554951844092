const colors = {
  primary: '#21409A',
  secondary: '#F36F21',
  orange400: '#F58C4D',
  orange600: '#DB641E',
  blue50: '#E9ECF5',
  blue400: '#4D66AE',
  blue600: '#1A337B',
  blue700: '#14265C',
  blueText: '#1D4B8D',
  black: '#000',
  borders: '#C9C9C9',
  borders2: 'rgba(255, 255, 255, 0.25)',
  borders3: '#dfdede',
  text: '#070707',
  text2: '#191f28',
  white: '#FFFFFF',
  disabled: '#898989',
  neutral50: '#EDEDED',
  neutral100: '#C9C9C9',
  neutral200: '#AEAEAE',
  neutral300: '#898989',
  neutral400: '#696969',
  neutral500: '#4A4A4A',
  neutral600: '#3B3B3B',
  neutral800: '#161616',
  neutral900: '#070707',
  lightBlue: '#E9ECF5',
  lightNeutral50: '#F9F9F9',
  lightNeutral100: '#F6F6F6',
  lightNeutral200: '#ECEDED',
  heading: '#333333',
  caption: '#666666',
  tick: '#447E00',
  green: '#449400',
  circleArcGraphOrange: '#F09830',
  success50: '#EFFDDE',
  success400: '#68BC09',
  success500: '#569B07',
  success900: '#284903',
  get menu() {
    return this.neutral300;
  },
  get activeMenu() {
    return this.white;
  },
  error500: '#D0021B',
  bronzeTier: '#19BAAA',
  silverTier: '#54A2D9',
  goldTier: '#234F8C',
};

export default colors;
