import React, { useLayoutEffect, useRef, useState } from 'react';
import BaseLink from '~common/atoms/Link';
import styled from '@emotion/styled';
import useBranding from '~lib/hooks/sanity/useBranding';
import { Box } from 'rebass';
import { fetchSvgInline } from '~lib/util';
import { useMobileScreenOnly } from '~lib/responsive';

const toUnit = size => {
  if (typeof size === 'number') {
    return `${size}px`;
  }

  return size;
};

const ImageContainer = styled(Box)`
  ${props => props.theme.mq.lg} {
    transform: translateX(-${props => props.leftOffset}px);
  }
`;

const Logo = ({
  height: generalHeight = 48,
  mobileHeight = 40,
  alternate = false,
  applyOffset = true,
  className,
  onClick,
}) => {
  const [leftOffset, setLeftOffset] = useState();
  const { logo, altLogo } = useBranding();
  const imgRef = useRef();
  const isMobile = useMobileScreenOnly();
  const height = isMobile ? mobileHeight : generalHeight;

  useLayoutEffect(() => {
    fetchSvgInline({
      height: toUnit(height),
      src: alternate ? altLogo : logo,
    })
      .then(svg => {
        if (imgRef.current) {
          imgRef.current.innerHTML = '';
          imgRef.current?.appendChild(svg);
          const { width: hearthPathWidth } = svg
            .querySelector('path')
            .getBoundingClientRect();
          setLeftOffset(hearthPathWidth ? hearthPathWidth + 3 : 0);
        }
      })
      .catch(error => console.error(error));
  }, [isMobile]);
  return (
    <BaseLink
      onClick={evt => {
        if (onClick) {
          evt.preventDefault();
          onClick(evt);
        }
      }}
      className={className}
      to="/"
    >
      <ImageContainer
        leftOffset={applyOffset ? leftOffset : 0}
        ref={imgRef}
      ></ImageContainer>
    </BaseLink>
  );
};

Logo.displayName = 'Logo';

export default Logo;
