exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-application-form-js": () => import("./../../../src/templates/application-form.js" /* webpackChunkName: "component---src-templates-application-form-js" */),
  "component---src-templates-compare-products-compare-extras-products-js": () => import("./../../../src/templates/compare-products/CompareExtrasProducts.js" /* webpackChunkName: "component---src-templates-compare-products-compare-extras-products-js" */),
  "component---src-templates-compare-products-compare-hospital-products-js": () => import("./../../../src/templates/compare-products/CompareHospitalProducts.js" /* webpackChunkName: "component---src-templates-compare-products-compare-hospital-products-js" */),
  "component---src-templates-contact-us-index-js": () => import("./../../../src/templates/contact-us/index.js" /* webpackChunkName: "component---src-templates-contact-us-index-js" */),
  "component---src-templates-news-index-js": () => import("./../../../src/templates/news/index.js" /* webpackChunkName: "component---src-templates-news-index-js" */),
  "component---src-templates-news-news-article-index-js": () => import("./../../../src/templates/news/news-article/index.js" /* webpackChunkName: "component---src-templates-news-news-article-index-js" */),
  "component---src-templates-oms-my-cover-upgrade-js": () => import("./../../../src/templates/oms/my-cover/upgrade.js" /* webpackChunkName: "component---src-templates-oms-my-cover-upgrade-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-product-index-js": () => import("./../../../src/templates/product/index.js" /* webpackChunkName: "component---src-templates-product-index-js" */),
  "component---src-templates-quote-js": () => import("./../../../src/templates/quote.js" /* webpackChunkName: "component---src-templates-quote-js" */),
  "component---src-templates-quote-old-js": () => import("./../../../src/templates/quote-old.js" /* webpackChunkName: "component---src-templates-quote-old-js" */),
  "component---src-templates-request-account-deletion-index-js": () => import("./../../../src/templates/request-account-deletion/index.js" /* webpackChunkName: "component---src-templates-request-account-deletion-index-js" */),
  "component---src-templates-resume-application-form-js": () => import("./../../../src/templates/resume-application-form.js" /* webpackChunkName: "component---src-templates-resume-application-form-js" */)
}

