import React from 'react';
import { useContactUsForms } from '~OMS/contact-us/useContactUsForms';
import EmailUsLightBox from '~OMS/contact-us/EmailUsLightBox';
import RequestCallbackLightBox from '~OMS/contact-us/RequestCallbackLightBox';

const ContactUsContext = React.createContext({});

const ContactUsProvider = ({ children }) => {
  const {
    state,
    submitEmailUsForm,
    resetView,
    submitRequestCallBackForm,
    openRequestCallback,
    openEmailUs,
  } = useContactUsForms();
  return (
    <ContactUsContext.Provider
      value={{
        openEmailUs,
        openRequestCallback,
      }}
    >
      <>
        <RequestCallbackLightBox
          isAnomUser={state.isAnomUser}
          view={state.view}
          loading={state.loading}
          onClose={resetView}
          form={state.form}
          confirmationMessage={state.requestCallbackConfirmationMessage}
          onSubmit={submitRequestCallBackForm}
          initialValues={state.initialRequestCallbackFormValues}
        />
        <EmailUsLightBox
          initialValues={state.initialEmailUsFormValues}
          isAnomUser={state.isAnomUser}
          onSubmit={submitEmailUsForm}
          loading={state.loading}
          view={state.view}
          onClose={resetView}
        />
        {children}
      </>
    </ContactUsContext.Provider>
  );
};

export { ContactUsContext, ContactUsProvider };
