import React from 'react';
import ThemeFactory from '~lib/theme';

import { ThemeProvider } from 'theme-ui';

export default props => {
  const theme = ThemeFactory();

  return theme && <ThemeProvider theme={theme} {...props} />;
};
