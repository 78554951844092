import moment from 'moment';

const toMoment = dateString => moment(dateString, moment.ISO_8601);

export const formatMoment = (date, format) =>
  date && date.isValid() ? date.format(format) : '';

export const toDateOnly = date => moment(date).format('YYYY-MM-DD');

export const formatDate = dateString => {
  return moment(dateString, moment.ISO_8601).format('DD/MM/YYYY');
};

export const formatDateTime = dateString => {
  return moment(dateString, moment.ISO_8601).format('DD/MM/YYYY HH:mm');
};

export const wordyFormat = dateString =>
  moment(dateString, moment.ISO_8601).format('DD MMMM YYYY');

export const shortWordyFormat = dateString =>
  formatMoment(toMoment(dateString), 'DD MMM YYYY');

export const shortWordyDateTimeFormat = dateString =>
  formatMoment(toMoment(dateString), 'DD MMM YYYY, hh:mm A');

export const shortFormat = dateString => toMoment(dateString).format('MM/YYYY');

export const compare = (dateString1, dateString2, limitTo) => {
  const date1 = toMoment(dateString1);
  const date2 = toMoment(dateString2);
  if (date1.isBefore(date2)) {
    return -1;
  }

  if (date1.isSame(date2, limitTo)) {
    return 0;
  }

  return 1;
};

export const compareDateOnly = (dateString1, dateString2) => {
  return compare(dateString1, dateString2, 'day');
};

export const isInPast = date => {
  return toMoment(date).isBefore(moment().subtract(1, 'day'));
};

export const smartFormat = dateString => {
  const date = toMoment(dateString);
  const format = date.year() === moment().year() ? 'Do MMMM' : 'Do MMMM, YYYY';
  return date.format(format);
};

export const toDate = toMoment;

export const now = () => moment();

export const formatToISO8601DateOnly = momentOrDateString => {
  return moment(momentOrDateString).format('YYYY-MM-DD');
};

export const fromNativeDate = nativeDateObject => moment(nativeDateObject);

export const getAge = date => {
  return now().diff(date, 'year');
};

export const getAgeDuration = date => {
  return moment.duration(now().diff(date));
};

export const isBetween = (date, dateA, dateB, unit, inclusivity) => {
  return toMoment(date).isBetween(dateA, dateB, unit, inclusivity);
};
