import React from 'react';
import ReactDOM from 'react-dom';
import { Global, css } from '@emotion/react';
import BrowserRenderOnly from '~lib/hocs/BrowserRenderOnly';
import { Box } from 'rebass';
import styled from '@emotion/styled';

const Shade = styled(Box)`
  position: absolute;
  width: 100%;
  height: 100%;
  background: ${props => props.theme.colors.dark};
  opacity: 0.8;
`;

class Overlay extends React.Component {
  constructor() {
    super();
    this.wrapper = document.createElement('div');
    Object.assign(this.wrapper.style, {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: 'transparent',
      'z-index': 999,
      display: 'none',
      justifyContent: 'center',
      alignItems: 'center',
    });

    this.wrapper.classList.add('dhf-overlay');

    this.state = {
      isShown: false,
    };
  }

  componentDidMount() {
    document.body.appendChild(this.wrapper);
  }

  componentWillUnmount() {
    document.body.removeChild(this.wrapper);
  }

  toggle = isShown => {
    this.setState({
      isShown,
    });

    if (isShown) {
      this.wrapper.style.display = 'flex';
    } else {
      this.wrapper.style.display = 'none';
    }
  };

  renderChildren() {
    this.props.grabToggler(this.toggle);
    return (
      <>
        {this.props.showShade && (
          <Shade
            onClick={() => {
              if (this.props.onOverlayClick) {
                this.props.onOverlayClick(this.state.isShown);
              }
            }}
          />
        )}
        {this.state.isShown && (
          <Global
            styles={css`
              html {
                height: unset;
                min-height: 100%;
              }

              body {
                overflow: hidden;
                height: unset;
                min-height: 100%;
              }
            `}
          />
        )}
        {this.state.isShown ? (
          this.props.children({ isShown: this.isShown })
        ) : (
          <span></span>
        )}
      </>
    );
  }

  render() {
    return ReactDOM.createPortal(this.renderChildren(), this.wrapper);
  }
}

Overlay.defaultProps = {
  showShade: true,
};

export default BrowserRenderOnly(Overlay);
