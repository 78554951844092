import { useCallback, useState } from 'react';

// TODO: user the storage module from ~lib instead of directly referencing the window.localStorage
const useLocalStorage = (
  key,
  initialValue,
  { mapFormBeforePersist, mapFormBeforeRead = x => x } = {}
) => {
  const [storedValue, setStoredValue] = useState(() => {
    initialValue =
      typeof initialValue === 'function' ? initialValue() : initialValue;
    if (typeof window === 'undefined') {
      return initialValue;
    }

    try {
      const item = window.localStorage.getItem(key);
      return mapFormBeforeRead(item ? JSON.parse(item) : initialValue);
    } catch (err) {
      console.error(err);
      return initialValue;
    }
  });

  const storeToLocalStorage = value => {
    if (typeof window !== 'undefined') {
      window?.localStorage?.setItem(key, JSON.stringify(value));
    }
  };

  const setValue = useCallback(
    value => {
      try {
        setStoredValue(currentValue => {
          const valueToStore =
            typeof value === 'function' ? value(currentValue) : value;

          if (mapFormBeforePersist) {
            const mappedPersistValue = mapFormBeforePersist(valueToStore);
            storeToLocalStorage(mappedPersistValue);
          } else {
            storeToLocalStorage(valueToStore);
          }

          return valueToStore;
        });
      } catch (err) {
        console.error(err);
      }
    },
    [setStoredValue]
  );

  const removeValue = useCallback(() => {
    localStorage.removeItem(key);
  }, [key]);

  return [storedValue, setValue, removeValue];
};

export default useLocalStorage;
