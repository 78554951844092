import breakpoints from '~lib/breakpoints';
import { useMediaQuery } from 'react-responsive';

export const useDesktopOrLaptop = () =>
  useMediaQuery({
    query: `(min-device-width: ${breakpoints.lg})`,
  });

export const useBigScreen = () =>
  useMediaQuery({ query: `(min-device-width: ${breakpoints.xl})` });

export const useMobileScreen = () =>
  useMediaQuery({
    query: `(max-width: ${breakpoints.md})`,
  });

export const useMobileLandscapeOnlyOnly = () =>
  useMediaQuery({
    query: `(max-width: ${
      Number.parseInt(breakpoints.tabletL) - 1
    }) and (orientation: landscape)`,
  });

export const useMobileScreenOnly = () =>
  useMediaQuery({
    query: `(max-width: ${Number.parseInt(breakpoints.tabletP) - 1}px)`,
  });

export const useMobileScreenAndBigger = () =>
  useMediaQuery({
    query: `(min-width: ${breakpoints.sm})`,
  });

export const useLaptopScreenAndBigger = () =>
  useMediaQuery({
    query: `(min-width: ${breakpoints.md})`,
  });

export const useTabletLandscapeAndBigger = () =>
  useMediaQuery({
    query: `(min-width: ${breakpoints.tabletL})`,
  });

export const useTabletLandscapeOnly = () =>
  useMediaQuery({
    query: `(min-width: ${breakpoints.tabletL}) and (max-width: ${breakpoints.lg}) and (orientation: landscape)`,
  });

export const useTabletPortraitOnly = () =>
  useMediaQuery({
    query: `(min-width: ${breakpoints.tabletP}) and (max-width: ${breakpoints.tabletL}) and (orientation: portrait)`,
  });

export const usePortrait = () =>
  useMediaQuery({ query: '(orientation: portrait)' });

export const useRetina = () =>
  useMediaQuery({ query: '(min-resolution: 2dppx)' });

export default Object.entries(breakpoints).reduce(
  (acc, [bpName, bpValue]) => ({
    ...acc,
    [bpName]: () =>
      useMediaQuery({
        query: `(min-width: ${bpValue})`,
      }),
    [`${bpName}Inverted`]: () =>
      useMediaQuery({
        query: `(max-width: ${bpValue})`,
      }),
  }),
  {}
);
