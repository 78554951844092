import React from 'react';
import { withTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Box, Flex } from 'rebass';
import { H2 } from '../atoms/typography';
import { shouldForwardProp } from '~lib/util';
import { useThemeUI } from 'theme-ui';
import { Button } from '../atoms/Button';

const StyledWrapper = styled(Box, { shouldForwardProp })`
  display: flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
`;

const StyledTitle = styled(H2)`
  font-weight: normal;
  max-width: 80%;
  text-align: center;
  margin-bottom: ${props => props.theme.space[3]}px;
`;

const StyledMessage = styled(Box)`
  color: ${props => props.theme.colors.text.light2};
  font-size: ${props => props.theme.fontSizes[2]}px;
`;

function ModalConfirmation({
  title,
  message,
  onConfirm,
  confirmText = 'Return to dashboard',
  cancelText,
  onCancel,
  confirmLoading,
  alignItems = 'center',
  confirmDisabled,
  cancelDisabled,
  confirmButtonVariant = 'primary',
  cancelButtonVariant = 'primaryInverted',
  ...props
}) {
  const { theme } = useThemeUI();
  return (
    <StyledWrapper alignItems={alignItems} theme={theme} {...props}>
      <StyledTitle color="primary" data-id="confirm-modal-title">
        {title}
      </StyledTitle>
      <StyledMessage data-id="confirm-modal-message" mt={2} mb={4}>
        {message}
      </StyledMessage>
      <Flex justifyContent="center" width={1}>
        <Button
          data-id="confirm-modal"
          wrapperProps={{
            mr: 2,
          }}
          variant={confirmButtonVariant}
          loading={confirmLoading}
          onClick={onConfirm}
          disabled={confirmDisabled}
        >
          {confirmText}
        </Button>
        {onCancel && cancelText && (
          <Button
            data-id="cancel-modal"
            variant={cancelButtonVariant}
            onClick={onCancel}
            disabled={cancelDisabled}
          >
            {cancelText}
          </Button>
        )}
      </Flex>
    </StyledWrapper>
  );
}

export default withTheme(ModalConfirmation);
