import React from 'react';
import Icon from '../atoms/Icon';
import Popover from '../../components/Elements/Popover';
import styled from '@emotion/styled';
import { useThemeUI } from 'theme-ui';
import { Box } from 'rebass';
const StyledInfoIcon = styled(Icon)`
  &&& circle {
    fill: ${props =>
      props.iconFill
        ? props.theme.colors[props.iconFill] || props.iconFill
        : ''};
  }
`;

const InfoIcon = ({
  tooltip,
  position = 'right',
  iconFill,
  icon,
  ...props
}) => {
  const { theme } = useThemeUI();
  icon = icon || { width: '20px', height: '20px', fill: '' };
  return (
    <Popover
      className="info-icon"
      position={position}
      content={tooltip}
      theme="dark"
    >
      <Box>
        <StyledInfoIcon
          iconFill={icon.fill}
          fill={theme.colors.primary}
          width={icon.width}
          height={icon.height}
          name={icon.name || 'help'}
          {...props}
        />
      </Box>
    </Popover>
  );
};

export default InfoIcon;
