import { graphql, useStaticQuery } from 'gatsby';

const CONTACT_DETAILS_QUERY = graphql`
  query ContactDetailsQuery {
    sanityContactDetails {
      email
      fax
      abn
      phone
      organisationName
      workingTime {
        timezone
        days
        hours {
          from
          to
        }
      }
      addresses {
        office
        mailing
      }
      disclaimer {
        blockContent: _rawBlockContent
      }
    }
  }
`;

export default () => {
  const { sanityContactDetails } = useStaticQuery(CONTACT_DETAILS_QUERY);
  return sanityContactDetails;
};
