import { useState, useEffect } from 'react';
import { isRunningOnClientSide, noop } from '~lib/util';

function useMarketoForm({
  baseUrl,
  munchkinId,
  formId,
  onLoad = noop,
  onSuccess = noop,
}) {
  const [form, setForm] = useState();

  const isScriptLoaded = isRunningOnClientSide() && window.MktoForms2;
  console.log(window.MktoForms2);

  const initForm = () => {
    window.MktoForms2.loadForm(baseUrl, munchkinId, formId, value => {
      value.onSuccess(onSuccess);
      value.onValidate(isValid => {
        value.submittable(isValid);
      });
      setForm(value);
      onLoad(value);
    });
  };

  const loadScript = ({ onLoad }) => {
    const script = document.createElement('script');
    script.src = `${baseUrl}/js/forms2/js/forms2.min.js`;
    script.onload = onLoad;
    document.body.appendChild(script);
  };

  useEffect(() => {
    if (!isRunningOnClientSide()) {
      return;
    }
    if (isScriptLoaded) {
      initForm();
    } else {
      loadScript({
        onLoad: initForm,
      });
    }
  }, [baseUrl, munchkinId, formId]);

  return {
    id: `mktoForm_${formId}`,
    form,
    loading: !isScriptLoaded,
  };
}

export default useMarketoForm;
