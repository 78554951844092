import React, { useEffect, useState } from 'react';
import remark from 'remark';
import recommended from 'remark-preset-lint-recommended';
import markdownToHtml from '~lib/markdownToHtml';
import { css } from '@emotion/react';
import { Box } from 'rebass';
import sanitizeHtml from '~lib/sanitizeHtml';

export const HTMLContent = ({
  content = '',
  markdown = '',
  className,
  css: cssOverrides,
  ...props
}) => {
  const [html, setHtml] = useState();

  useEffect(() => {
    const compiled = markdown
      ? remark()
          .use(recommended)
          .use(markdownToHtml)
          .processSync(markdown)
          .toString()
      : '';

    const finalContent = content + compiled;

    setHtml(sanitizeHtml(finalContent));
  }, [content, markdown]);

  return (
    <Box
      className={className}
      dangerouslySetInnerHTML={{ __html: html }}
      css={css`
        font-size: 16px;
        line-height: 21px;

        ul {
          list-style: disc;
          padding-left: 21px;
          margin-top: 20px;
        }

        p,
        li {
          font-size: 16px;
          line-height: 21px;
          margin-bottom: 16px;
        }

        li {
          padding-left: 8px;
        }

        ${cssOverrides || ''}
      `}
      {...props}
    />
  );
};

export default HTMLContent;
