import colors from './colors';
import fonts from './fonts';
import buttons from './buttons';
import sizes from './sizes';
import fontSizes from './fontSizes';
import styles from './styles';

export default {
  colors,
  fonts,
  buttons,
  sizes,
  fontSizes,
  styles,
};
