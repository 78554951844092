import { css } from '@emotion/react';

export const getFocusColor = props => {
  if (props.focusColor) {
    return props.focusColor;
  }
  return props.error ? props.theme.colors.error : props.theme.colors.primary;
};

export const focusStyles = props => css`
  transition: all 300ms ease-out;
  &&&:focus {
    box-shadow: 0 0 5px ${getFocusColor(props)};
    ${!props.error ? `border: 1px solid rgba(255, 255, 255, 0)` : ''}
  }
`;

export const focusStylesInline = props => css`
  transition: all 300ms ease-out;
  box-shadow: 0 0 5px ${getFocusColor(props)}!important;
  ${!props.error ? `border: 1px solid rgba(255, 255, 255, 0)` : ''}
`;
