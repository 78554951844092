import colors from './colors';
import fontSizes from '~lib/theme/rebrand/fontSizes';

const sizes = {
  small: {
    minWidth: '135px',
    height: '34px',
    padding: '8px 16px',
    fontSize: `${fontSizes[2]}px`,
    lineHeight: '1.3',
  },
  medium: {
    minWidth: '153px',
    height: '42px',
    padding: '10px 18px',
    fontSize: `${fontSizes[3]}px`,
    lineHeight: '1.4',
  },
  large: {
    minWidth: '168px',
    height: '50px',
    padding: '14px 18px',
    fontSize: `${fontSizes[4]}px`,
    lineHeight: '1.2',
  },
};

const sharedButton = {
  borderWidth: '2px',
  borderStyle: 'solid',
  borderColor: 'transparent',
  borderRadius: '60px',
  backgroundColor: 'transparent',
  fontSize: '16px',
  fontWeight: 600,
  display: 'flex',
  flexWrap: 'nowrap',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
  padding: '8px 22px',
  lineHeight: '1.2',
  textDecoration: 'none !important',
  transition:
    '0.2s background-color ease-in-out, 0.2s border-color ease-in-out, 0.2s color ease-in-out',
  'svg path': {
    transition: '0.2s all ease-in-out',
  },
  '&[data-size="small"]': sizes.small,
  '&[data-size="medium"]': sizes.medium,
  '&[data-size="large"]': sizes.large,
};

const sharedLinkButton = {
  appearance: 'none',
  border: 'none',
  backgroundColor: 'transparent',
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: '21px',
  textDecoration: 'underline',
  padding: 0,
};

const sharedMinimalButton = {
  appearance: 'none',
  border: 'none',
  backgroundColor: 'transparent',
  padding: 0,
};

const buttons = {
  primary: {
    ...sharedButton,
    borderColor: colors.primary,
    backgroundColor: colors.primary,
    color: colors.white,
    ':hover': {
      backgroundColor: colors.blue400,
      borderColor: colors.blue400,
    },
    ':active': {
      borderColor: colors.blue600,
      backgroundColor: colors.blue600,
      color: colors.white,
    },
    ':disabled, :disabled:hover': {
      borderColor: colors.neutral100,
      backgroundColor: colors.neutral100,
      color: colors.white,
    },
  },
  primaryInverted: {
    ...sharedButton,
    borderColor: colors.primary,
    backgroundColor: colors.white,
    color: colors.primary,
    ':hover': {
      borderColor: colors.primary,
      backgroundColor: colors.primary,
      color: colors.white,
      'svg path': {
        fill: colors.white,
      },
    },
    ':active': {
      borderColor: colors.blue600,
      backgroundColor: colors.blue600,
      color: colors.white,
    },
    ':disabled, :disabled:hover': {
      borderColor: colors.neutral100,
      backgroundColor: colors.white,
      color: colors.neutral100,
    },
  },
  secondary: {
    ...sharedButton,
    borderColor: colors.secondary,
    backgroundColor: colors.secondary,
    color: colors.white,
    ':hover': {
      backgroundColor: colors.orange400,
      borderColor: colors.orange400,
    },
    ':active': {
      borderColor: colors.orange600,
      backgroundColor: colors.orange600,
      color: colors.white,
    },
    ':disabled, :disabled:hover': {
      borderColor: colors.neutral100,
      backgroundColor: colors.neutral100,
      color: colors.white,
    },
  },
  secondaryInverted: {
    ...sharedButton,
    borderColor: colors.secondary,
    backgroundColor: colors.white,
    color: colors.secondary,
    ':hover': {
      borderColor: colors.secondary,
      backgroundColor: colors.secondary,
      color: colors.white,
    },
    ':active': {
      borderColor: colors.orange600,
      backgroundColor: colors.orange600,
      color: colors.white,
    },
    ':disabled, :disabled:hover': {
      borderColor: colors.neutral100,
      backgroundColor: colors.white,
      color: colors.neutral100,
    },
  },
  white: {
    ...sharedButton,
    borderColor: colors.white,
    backgroundColor: colors.white,
    color: colors.primary,
    ':hover': {
      borderColor: colors.white,
      backgroundColor: colors.white,
      color: colors.primary,
    },
    ':active': {
      borderColor: colors.white,
      backgroundColor: colors.white,
      color: colors.primary,
    },
    ':disabled, :disabled:hover': {
      borderColor: colors.neutral100,
      backgroundColor: colors.white,
      color: colors.neutral100,
    },
  },
  whiteInverted: {
    ...sharedButton,
    borderColor: colors.white,
    backgroundColor: 'transparent',
    color: colors.white,
    ':hover': {
      borderColor: colors.white,
      backgroundColor: colors.white,
      color: colors.primary,
    },
    ':active': {
      borderColor: colors.white,
      backgroundColor: colors.white,
      color: colors.primary,
    },
    ':disabled, :disabled:hover': {
      borderColor: colors.neutral100,
      backgroundColor: colors.white,
      color: colors.neutral100,
    },
  },
  primaryLink: {
    ...sharedLinkButton,
    color: colors.primary,
  },
  secondaryLink: {
    ...sharedLinkButton,
    color: colors.secondary,
  },
  minimal: {
    ...sharedMinimalButton,
  },
};

export default buttons;
