export const getHeaders = headers => ({
  ...(process.env.OMS_FUND_ID
    ? {
        [process.env.OMS_FUND_ID_HEADER || 'X-Fund-Id']:
          process.env.OMS_FUND_ID,
      }
    : {}),
  ...headers,
});

export function customFetch(url, opts = {}) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    opts = {
      ...opts,
      headers: getHeaders(opts.headers),
    };
    xhr.open(opts.method || 'get', url);

    for (const k in opts.headers || {}) {
      xhr.setRequestHeader(k, opts.headers[k]);
    }

    let getTokenPromise = Promise.resolve(null);
    if (typeof window !== 'undefined' && window.AwsWafIntegration?.getToken) {
      getTokenPromise = window.AwsWafIntegration.getToken();
    }

    getTokenPromise
      .then(token => {
        if (token) {
          xhr.setRequestHeader('x-aws-waf-token', token);
        }

        xhr.onload = evt => {
          resolve(
            new Response(xhr.response, {
              status: evt.target.status,
              statusText: evt.target.statusText,
              headers: opts.headers,
            })
          );
        };

        xhr.onerror = reject;

        if (xhr.upload && opts.onUploadProgress) {
          xhr.upload.onprogress = event =>
            opts.onUploadProgress({
              loaded: event.loaded,
              total: event.total,
              progress: (event.loaded / event.total) * 100,
            });
        }

        xhr.send(opts.body);
      })
      .catch(reject); // Reject if the getTokenPromise fails
  });
}
