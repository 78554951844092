import { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { cloneDeep } from '@apollo/client/utilities';
import { MyDetailsQuery } from 'queries/oms/myDetails.graphql';

import {
  ContactUs,
  RequestCallback,
  DrawerQuery,
} from 'queries/oms/common.graphql';
import { formatDate, formatDateTime } from '~lib/dates';
import { getLoggedInUser, getMembershipAddress } from '~OMS/response-selectors';
import { Auth } from 'aws-amplify';

export const VIEWS = {
  EMAIL_US: 'emailUs',
  REQUEST_CALLBACK: 'requestCallBack',
  REQUEST_CALLBACK_CONFIRM: 'requestCallBackConfirm',
  EMAIL_US_CONFIRM: 'emailUsConfirm',
};

export const useContactUsForms = () => {
  const [state, setState] = useState({
    view: '',
    form: undefined,
    isAnomUser: false,
  });
  const [userData, setUserData] = useState();

  useEffect(() => {
    const getAuthUser = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        setUserData(user);
      } catch (err) {
        setUserData(null);
        console.error('Error fetching authenticated user:', err);
      }
    };
    getAuthUser();
  }, []);

  const [contactUs, { loading: contactUsLoading }] = useMutation(ContactUs);
  const [requestCallback, { loading: requestCallbackLoading }] =
    useMutation(RequestCallback);

  const setView = useCallback(view => {
    setState({
      view,
    });
  }, []);

  const resetView = useCallback(() => {
    setView('');
  }, [setView]);

  const openRequestCallback = useCallback(
    ({ isAnomUser = false } = {}) => {
      setState({
        ...state,
        view: VIEWS.REQUEST_CALLBACK,
        isAnomUser,
      });
    },
    [state]
  );

  const openEmailUs = useCallback(({ isAnomUser = false }) => {
    setState(currentState => ({
      ...currentState,
      view: VIEWS.EMAIL_US,
      isAnomUser,
    }));
  }, []);

  const submitRequestCallBackForm = async formData => {
    const input = {
      name: `${formData.FirstName} ${formData.LastName}`,
      phone: formData.Phone,
      state: formData.State,
    };

    if (formData.Date) {
      const { Date, Time } = formData;
      input.date = Date.hour(Time ? Time.hour() : 0)
        .minutes(Time ? Time.minutes() : 0)
        .seconds(0);
    }

    await requestCallback({
      variables: {
        input,
      },
      update: (store, { data: { requestCallback } }) => {
        if (requestCallback && requestCallback.activity) {
          try {
            const data = cloneDeep(store.readQuery({ query: DrawerQuery }));
            if (data) {
              data.oms.activities.unshift(requestCallback.activity);
              store.writeQuery({ query: DrawerQuery, data });
            }
          } catch (ex) {
            // Drawer available for logged in users only.
          }
        }
      },
    });

    setState({
      view: VIEWS.REQUEST_CALLBACK_CONFIRM,
      form: {
        ...formData,
        dateTime: input.date,
      },
    });
  };

  const submitEmailUsForm = async formData => {
    console.dir(formData);

    await contactUs({
      update: (store, { data: { contactUs } }) => {
        if (contactUs && contactUs.activity) {
          const data = cloneDeep(store.readQuery({ query: DrawerQuery }));
          if (data) {
            data.oms.activities.unshift(contactUs.activity);
            store.writeQuery({ query: DrawerQuery, data });
          }
        }
      },
    });
    setState({
      view: VIEWS.EMAIL_US_CONFIRM,
      form: formData,
    });
  };

  const requestCallbackConfirmationMessage = useMemo(() => {
    if (!state.form) {
      return '';
    }

    const { dateTime, time, date } = state.form;
    if (!dateTime) {
      return 'Expect a call from one of our expert staff soon.';
    }

    const formattedDate = !time ? formatDate(date) : formatDateTime(dateTime);
    return `Expect a call from one of our expert staff at ${formattedDate}`;
  }, [state]);

  const { loading: myyDetailsLoading, data: myDetails } = useQuery(
    MyDetailsQuery,
    {
      skip:
        !userData ||
        ![VIEWS.EMAIL_US, VIEWS.REQUEST_CALLBACK].includes(state.view),
    }
  );

  const { initialEmailUsFormValues, initialRequestCallbackFormValues } =
    useMemo(() => {
      if (!myDetails) {
        return {};
      }
      const loggedInUser = getLoggedInUser(myDetails);
      const address = getMembershipAddress(myDetails);

      const [firstName, lastName] = loggedInUser.fullName?.split(' ');
      return {
        initialEmailUsFormValues: {
          FirstName: firstName,
          LastName: lastName,
          Phone: loggedInUser.mobilePhone,
          Email: loggedInUser.email,
          State: address.state,
        },
        initialRequestCallbackFormValues: {
          FirstName: firstName,
          LastName: lastName,
          Phone: loggedInUser.mobilePhone,
          Email: loggedInUser.email,
          State: address.state,
        },
      };
    }, [myDetails]);

  return {
    state: {
      ...state,
      requestCallbackConfirmationMessage,
      loading: requestCallbackLoading || contactUsLoading || myyDetailsLoading,
      initialEmailUsFormValues,
      initialRequestCallbackFormValues,
    },
    submitEmailUsForm,
    submitRequestCallBackForm,
    setView,
    resetView,
    openRequestCallback,
    openEmailUs,
  };
};
