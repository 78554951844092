import sanitize from 'sanitize-html';

export default (rawHtml, options = {}) => {
  const allowedTags = [
    'b',
    'i',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'a',
    'strong',
    'em',
    'br',
    'p',
    'ul',
    'ol',
    'li',
  ];

  return sanitize(rawHtml, {
    ...options,
    allowedTags: options.allowedTags
      ? allowedTags.concat(options.allowedTags)
      : allowedTags,
  });
};
