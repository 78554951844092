import React, { useEffect } from 'react';
import WorkingModal from '../molecules/WorkingModal';
import ReactDom from 'react-dom';
import ModalConfirmation from '../molecules/ModalConfirmation';
import useModalState from '~lib/hooks/useModalState';
import { Alert } from '../molecules/AlertBox';
import ThemeProvider from '~lib/providers/ThemeProvider';

export const confirm = ({ alert, message, ...props }) => {
  let modalRoot = document.querySelector('service-modal-root');

  if (!modalRoot) {
    modalRoot = document.createElement('div');
    modalRoot.classList = 'service-modal-root';
    document.body.appendChild(modalRoot);
  }

  const ConfirmModal = () => {
    const { modalState, closeModal } = useModalState(true);

    const handleClose = () => {
      document.body.removeChild(modalRoot);
      closeModal();
      if (props.onCancel) {
        props.onCancel();
      }
    };

    const handleConfirm = () => {
      document.body.removeChild(modalRoot);
      closeModal();
      if (props.onConfirm) {
        props.onConfirm();
      }
    };

    const handleCancel = () => {
      document.body.removeChild(modalRoot);
      closeModal();
      if (props.onCancel) {
        props.onCancel();
      }
    };

    const formattedMessage = alert ? (
      <Alert type={alert.type}>{alert.message}</Alert>
    ) : (
      message
    );

    useEffect(() => {
      return () => {
        document.body.removeChild(modalRoot);
      };
    }, []);
    return (
      <ThemeProvider>
        <WorkingModal
          height="auto"
          minHeight="200px"
          centerV
          visible={modalState}
          onClose={handleClose}
        >
          <ModalConfirmation
            {...props}
            message={formattedMessage}
            onConfirm={handleConfirm}
            onCancel={handleCancel}
          />
        </WorkingModal>
      </ThemeProvider>
    );
  };

  ReactDom.render(<ConfirmModal />, modalRoot);
};

export const open = ({ content, ...props }) => {
  let modalRoot = document.querySelector('service-modal-root');

  if (!modalRoot) {
    modalRoot = document.createElement('div');
    modalRoot.classList = 'service-modal-root';
    document.body.appendChild(modalRoot);
  }

  const ConfirmModal = () => {
    const { modalState, closeModal } = useModalState(true);

    const handleClose = () => {
      document.body.removeChild(modalRoot);
      closeModal();
    };

    useEffect(() => {
      return () => {
        document.body.removeChild(modalRoot);
      };
    }, []);
    return (
      <ThemeProvider>
        <WorkingModal
          height="auto"
          minHeight="200px"
          centerV
          visible={modalState}
          onClose={handleClose}
          {...props}
        >
          {content({
            close: handleClose,
          })}
        </WorkingModal>
      </ThemeProvider>
    );
  };

  ReactDom.render(<ConfirmModal />, modalRoot);
};
