import React from 'react';
import styled from '@emotion/styled';
import { P } from '../../../common/atoms/typography';
import { withTheme } from '@emotion/react';

import { MarketoForm } from '~common/organisms/Marketo';
import Logo from '~common/atoms/Logo';

const StyledFormWrapper = styled.div`
  width: ${props => (props.fullStretch ? '100%' : '400px')};
`;

const StyledLogo = styled(Logo)`
  display: block;
  margin-bottom: ${props => props.theme.space[3]}px;
`;

const RequestCallBackFormContainer = ({
  onSubmit,
  theme,
  className,
  fullStretch = false,
  loading = false,
  initialValues,
}) => {
  return (
    <StyledFormWrapper
      data-id="request-callback-form"
      fullStretch={fullStretch}
      className={className}
    >
      <StyledLogo />
      <P marginBottom={theme.space[3]}>
        Get help from one of our friendly staff, Monday to Friday, 8.30am - 6pm.
      </P>
      <MarketoForm
        values={initialValues}
        formId={process.env.MARKETO_REQUEST_CALLBACK_FORM_FORM_ID}
        onSubmit={onSubmit}
        loading={loading}
      />
    </StyledFormWrapper>
  );
};

export default withTheme(RequestCallBackFormContainer);
